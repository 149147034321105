import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import "../../../style/animation.css";
import { FaSpinner } from "react-icons/fa6";
import { IoCloseOutline } from "react-icons/io5";
import { createFeedback } from "../../../service/feedback.service";
import { toast } from "react-toastify";

const DesktopFeedbackEmp = ({ EmpData }) => {
  const navigate = useNavigate();
  const [data, setData] = useState(null);
  const [btn, setBtn] = useState(false);
  const inputRef = useRef(null);
  const modalRef = useRef();

  const [loading, setLoading] = useState(false);
  const [isHidden, setIsHidden] = useState(false);

  //modal
  const [modal, setModal] = useState(1);
  const [modal2, setModal2] = useState(0);
  const [startCheck, setStartCheck] = useState(1);
  const [startOnGoing, setStartOngGoing] = useState(1);
  const [placed, setPlaced] = useState("");
  const [popUp, setPopUp] = useState(false);

  //start
  const [start1, setStart1] = useState("хэрэгжүүлж эхлэх зүйл");
  const [start2, setStart2] = useState("гарах үр дүн");
  const [startWrite, setStartWrite] = useState("");
  const [shouldAnimate, setShouldAnimate] = useState(false);

  //continue
  const [continue1, setContinue1] = useState("үргэлжлүүлэх зүйл");
  const [continue2, setContinue2] = useState("гарах үр дүн");
  const [continueWrite, setContinueWrite] = useState("");
  const [shouldAnimate2, setShouldAnimate2] = useState(false);

  //stop
  const [stop1, setStop1] = useState("зогсоох зүйл");
  const [stop2, setStop2] = useState("гарах үр дүн");
  const [stopWrite, setStopWrite] = useState("");
  const [shouldAnimate3, setShouldAnimate3] = useState(false);

  //popup2
  const [popUp2, setPopUp2] = useState(true);
  const popUpRef2 = useRef();

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        popUp2 &&
        popUpRef2.current &&
        !popUpRef2.current.contains(event.target)
      ) {
        setPopUp2(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [popUp2]);

  useEffect(() => {
    if (modal2 !== 0 && (startOnGoing === 1 || startOnGoing === 2)) {
      setPlaced("");
      inputRef.current.focus();
    }
  }, [modal2, startOnGoing]);

  useEffect(() => {
    let timer;
    if (
      (start1 !== "хэрэгжүүлж эхлэх зүйл" &&
        start2 !== "гарах үр дүн" &&
        start1 !== "" &&
        start2 !== "") ||
      startWrite !== ""
    ) {
      setBtn(true);
      timer = setTimeout(() => {
        setShouldAnimate(true);
      }, 1000);
    } else {
      setShouldAnimate(false);
    }
    return () => clearTimeout(timer);
  }, [start1, start2, startWrite]);

  useEffect(() => {
    let timer;
    if (
      (continue1 !== "үргэлжлүүлэх зүйл" &&
        continue2 !== "гарах үр дүн" &&
        continue1 !== "" &&
        continue2 !== "") ||
      continueWrite !== ""
    ) {
      setBtn(true);
      timer = setTimeout(() => {
        setShouldAnimate2(true);
      }, 1000);
    } else {
      setShouldAnimate2(false);
    }
    return () => clearTimeout(timer);
  }, [continue1, continue2, continueWrite]);

  useEffect(() => {
    let timer;
    if (
      (stop1 !== "зогсоох зүйл" &&
        stop2 !== "гарах үр дүн" &&
        stop1 !== "" &&
        stop2 !== "") ||
      stopWrite !== ""
    ) {
      setBtn(true);
      timer = setTimeout(() => {
        setShouldAnimate3(true);
      }, 1000);
    } else {
      setShouldAnimate3(false);
    }
    return () => clearTimeout(timer);
  }, [stop1, stop2, stopWrite]);

  useEffect(() => {
    if (EmpData !== undefined || EmpData !== null) {
      setData(EmpData);
    }
  }, [EmpData]);

  const handleModalClickOutside = (event) => {
    if (modalRef.current && !modalRef.current.contains(event.target)) {
      setStartOngGoing(1);
      setPopUp(false);
    }
  };

  const handleContinue = () => {
    if (startOnGoing === 1) {
      setStartOngGoing(2);
    } else {
      if (modal2 === 1) {
        setModal(3);
        setModal2(0);
        setStartOngGoing(1);
      } else if (modal2 === 3) {
        setModal(2);
        setModal2(0);
        setStartOngGoing(1);
      } else if (modal2 === 2) {
        setModal(2);
        setModal2(0);
        setPopUp(true);
      }
    }
  };

  if (data === null) {
    return (
      <div className="h-screen m-auto">
        <p className="text-[32px]">Loading...</p>
      </div>
    );
  }

  if (data === undefined) {
    return (
      <div className="h-screen m-auto">
        <p className="text-[32px]">Employee not found</p>
      </div>
    );
  }

  const getStartMsg = () => {
    return `та цаашид ${start1} эхэлвэл ${start2} чадна. Би таныг чадна гэдэгт итгэлтэй байна 🤗`;
  };
  const getContinueMsg = () => {
    return `та цаашид ${continue1} идэвхитэй үргэлжлүүвэл ${continue2} чадна. Keep going!✨`;
  };

  const getStopMsg = () => {
    return `та цаашид ${stop1} больж чадвал ${stop2} чадна. Амжилт!😎`;
  };

  const sendBackend = async () => {
    setLoading(true);
    try {
      let startMsg;
      let continueMsg;
      let stopMsg;

      if (
        start1 !== "хэрэгжүүлж эхлэх зүйл" &&
        start2 !== "гарах үр дүн" &&
        start1 !== "" &&
        start2 !== ""
      ) {
        startMsg = getStartMsg();
      } else if (startWrite !== "") {
        startMsg = startWrite;
      }

      if (
        continue1 !== "идэвхитэй үргэлжлүүлвэл" &&
        continue2 !== "гарах үр дүн" &&
        continue1 !== "" &&
        continue2 !== ""
      ) {
        continueMsg = getContinueMsg();
      } else if (continueWrite !== "") {
        continueMsg = continueWrite;
      }

      if (
        stop1 !== "зогсоох зүйл" &&
        stop2 !== "гарах үр дүн" &&
        stop1 !== "" &&
        stop2 !== ""
      ) {
        stopMsg = getStopMsg();
      } else if (stopWrite !== "") {
        stopMsg = stopWrite;
      }

      const types = [];

      if (startMsg !== undefined) {
        types.push({
          type: "START",
          text: startMsg,
        });
      }

      if (continueMsg !== undefined) {
        types.push({
          type: "CONTINUE",
          text: continueMsg,
        });
      }

      if (stopMsg !== undefined) {
        types.push({
          type: "STOP",
          text: stopMsg,
        });
      }

      const backendMsg = {
        receivingEmployeeId: EmpData.id,
        hide: isHidden,
        types,
      };

      await createFeedback(backendMsg)
        .then(() => {
          toast.success("Success");
          setLoading(false);
          navigate("/feed-forward");
        })
        .catch((e) => {
          setLoading(false);
          toast.error("Алдаа");
        });
    } catch (error) {
      console.error("Error:", error);
    }
  };

  return (
    <div>
      <div className="relative">
        <div className="m-auto pt-10 min-h-screen ">
          <div className="flex items-start justify-between">
            {/* <div className="flex flex-col items-center relative w-[200px]">
              <img src="/img/pfpOnPf.svg" alt="aa" />
              <p className="text-center text-[20px] font-semibold">
                {data.firstName} {data.lastName}
              </p>
              <p className="text-[#171717] text-center">
                {data.departmentName}, <br /> {data.position}
              </p>
            </div> */}
            <div className="flex flex-col items-center gap-10 mb-10">
              <div className="flex justify-start ">
                <div
                  onClick={() => {
                    setModal2(0);
                    setModal(1);
                    setStartOngGoing(1);
                  }}
                  className={`border rounded-full flex flex-col items-center justify-center pb-3 ${
                    shouldAnimate ? "fill-animation-bottom-left" : ""
                  } ${
                    modal === 1 || modal2 === 1
                      ? "border-[#51A8FF] w-[240px] h-[240px] shadow-xl"
                      : "border-[#C6C6C6] w-[200px] h-[200px]"
                  }`}
                  style={{
                    background: shouldAnimate ? "" : "white",
                  }}
                >
                  <img
                    className={`${
                      modal === 1 || modal2 === 1
                        ? "w-[69px] h-[69px]"
                        : "w-[60px] h-[60px]"
                    }`}
                    src={
                      EmpData.companyId === 197
                        ? "/img/feedback/icons/bIcon1Apu.jpeg"
                        : "/img/feedback/icons/bIcon1.svg"
                    }
                    alt="icon"
                  />
                  <p
                    className={`font-semibold mt-4 ${
                      shouldAnimate ? "text-white-animation" : "text-[#636466]"
                    } ${
                      modal === 1 || modal2 === 1
                        ? "text-[28px]"
                        : "text-[20px]"
                    }`}
                  >
                    START
                  </p>
                  <p
                    className={` text-center  ${
                      shouldAnimate ? "text-white-animation" : "text-[#A1A1A1]"
                    } ${
                      modal === 1 || modal2 === 1
                        ? "text-[16px] w-[217px]"
                        : "text-[12px] w-[160px]"
                    }`}
                  >
                    Цаашид юуг{" "}
                    <span className="font-semibold">хэрэгжүүлж эхэлбэл </span>
                    зохистой тухай зөвлөнө үү.
                  </p>
                </div>
              </div>
              <div className="flex justify-start">
                <div
                  onClick={() => {
                    setModal2(0);
                    setModal(3);
                    setStartOngGoing(1);
                  }}
                  className={`border border-[#C6C6C6] rounded-full w-[200px] h-[200px] flex flex-col items-center justify-center pb-3 ${
                    shouldAnimate3 ? "fill-animation-bottom-top" : ""
                  } ${
                    modal === 3 || modal2 === 3
                      ? "border-[#51A8FF] w-[270px] h-[270px] shadow-xl"
                      : "border-[#C6C6C6] w-[200px] h-[200px]"
                  }`}
                  style={{
                    background: shouldAnimate3 ? "" : "white",
                  }}
                >
                  <img
                    className={`${
                      modal === 3 || modal2 === 3
                        ? "w-[69px] h-[69px]"
                        : "w-[60px] h-[60px]"
                    }`}
                    src={
                      EmpData.companyId === 197
                        ? "/img/feedback/icons/bIcon3Apu.png"
                        : "/img/feedback/icons/bIcon3.svg"
                    }
                    alt="icon"
                  />
                  <p
                    className={` font-semibold mt-2 ${
                      shouldAnimate3 ? "text-white-animation" : "text-[#636466]"
                    } ${
                      modal === 3 || modal2 === 3
                        ? "text-[28px]"
                        : "text-[20px]"
                    }`}
                  >
                    STOP{" "}
                  </p>
                  <p
                    className={`text-center ${
                      shouldAnimate3 ? "text-white-animation" : "text-[#A1A1A1]"
                    } ${
                      modal === 3 || modal2 === 3
                        ? "text-[14px] w-[200px]"
                        : "text-[12px] w-[160px]"
                    }`}
                  >
                    Цаашид юуг{" "}
                    <span className="font-semibold">хийхээ зогсоовол </span>
                    зохистой тухай зөвлөнө үү.
                  </p>
                </div>
              </div>
              <div className="flex justify-start">
                <div
                  onClick={() => {
                    setModal2(0);
                    setModal(2);
                    setStartOngGoing(1);
                  }}
                  className={`border border-[#C6C6C6] rounded-full w-[200px] h-[200px] flex flex-col items-center justify-center  ${
                    shouldAnimate2 ? "fill-animation-bottom-top-left" : ""
                  } ${
                    modal === 2 || modal2 === 2
                      ? "border-[#51A8FF] w-[240px] h-[240px] shadow-xl"
                      : "border-[#C6C6C6] w-[190px] h-[190px]"
                  }`}
                  style={{
                    background: shouldAnimate2 ? "" : "white",
                  }}
                >
                  <img
                    className={`${
                      modal === 2 || modal2 === 2
                        ? "w-[69px] h-[69px]"
                        : "w-[60px] h-[60px]"
                    }`}
                    src={
                      EmpData.companyId === 197
                        ? "/img/feedback/icons/bIcon2Apu.png"
                        : "/img/feedback/icons/bIcon2.svg"
                    }
                    alt="icon"
                  />
                  <p
                    className={`font-semibold mt-2 ${
                      shouldAnimate2 ? "text-white-animation" : "text-[#636466]"
                    } ${
                      modal === 2 || modal2 === 2
                        ? "text-[28px]"
                        : "text-[20px]"
                    }`}
                  >
                    Continue
                  </p>
                  <p
                    className={`text-center ${
                      shouldAnimate2 ? "text-white-animation" : "text-[#A1A1A1]"
                    } ${
                      modal === 2 || modal2 === 2
                        ? "text-[14px] w-[217px]"
                        : "text-[12px] w-[160px]"
                    }`}
                  >
                    Цаашид юуг{" "}
                    <span className="font-semibold">
                      үргэлжлүүлэн хэрэгжүүлвэл{" "}
                    </span>
                    зохистой тухай зөвлөнө үү.
                  </p>
                </div>
              </div>
            </div>
            <div>
              <div className="flex items-center gap-2 mb-24">
                <div className="w-[50px] h-[50px] rounded-full border border-[#ccc]">
                  <img
                    className="w-full h-full object-cover rounded-full"
                    src={data.profileUrl}
                    alt="aaa"
                  />
                </div>
                <div>
                  <p className="text-[#1E293B]">
                    {EmpData.lastName} {EmpData.firstName}
                  </p>
                  <p className="text-sm text-[#666874]">
                    {EmpData.position}, {EmpData.departmentName}
                  </p>
                </div>
              </div>
              <div
                className={`rounded-xl bg-[#fff]  py-4 border border-[#efefef] max-w-[400px] shadow-md px-4  `}
              >
                <div className="px-2">
                  {modal === 1 ? (
                    <div>
                      <div className="flex items-center gap-2">
                        <img
                          className="h-[48px] w-[48px]"
                          src={
                            EmpData.companyId === 197
                              ? "/img/feedback/icons/bIcon1Apu.jpeg"
                              : "/img/feedback/icons/bIcon1.svg"
                          }
                          alt="icon"
                        />
                        <p className="font-semibold">Start</p>
                      </div>
                      <p className="text-xs opacity-70 w-[325px] mt-2">
                        Таны өгсөн Feed-forward тухайн ажилтны ажлын бүтээмж,
                        сэтгэлзүйд шууд нөлөөлөх боломжтой тул та эелдгээр
                        зохистой, хэрэгцээтэй зөвлөмжийг өгнө үү.
                      </p>
                      {startCheck === 1 ? (
                        <div className="mt-4">
                          <div
                            onClick={() => {
                              setModal2(1);
                              setModal(0);
                            }}
                            className="flex items-start gap-2"
                          >
                            <div
                              className={`h-4 w-4 rounded-full border-2 mt-2 ${
                                startCheck === 1
                                  ? "border-blue-600"
                                  : "border-gray-300"
                              }`}
                            />
                            <div>
                              <div
                                className={`w-[300px] border px-2 py-2 rounded-xl ${
                                  startCheck === 1
                                    ? EmpData.companyId === 197
                                      ? "border-[#41E6FF]"
                                      : "border-[#0752DF]"
                                    : "border-[#f2f2f2] "
                                }`}
                              >
                                <p
                                  className={`text-xs ${
                                    startCheck === 1
                                      ? EmpData.companyId === 197
                                        ? "text-[#191442]"
                                        : "text-[#0752DF]"
                                      : "text-[#A4A4A4]"
                                  }`}
                                >
                                  Та дараах өгүүлбэрийг нөхнө үү.
                                </p>

                                <p className="mt-2 text-xs text-[#222] opacity-70">
                                  <span className="text-[#7278ff] opacity-100">
                                    {data.firstname}
                                  </span>{" "}
                                  та цаашид
                                  <span className="underline-gradient  font-semibold opacity-100">
                                    {" "}
                                    {start1}{" "}
                                  </span>
                                  эхэлвэл
                                  <span className="underline-gradient  font-semibold opacity-100">
                                    {" "}
                                    {start2}{" "}
                                  </span>
                                  чадна.
                                </p>
                                <p className="text-xs text-[#222] opacity-70 my-3">
                                  Би таныг чадна гэдэгт итгэлтэй байна
                                  <span role="img" aria-label="smiley face">
                                    {" "}
                                    🤗
                                  </span>
                                </p>
                              </div>
                            </div>
                          </div>

                          <div
                            onClick={() => {
                              setStartCheck(2);
                            }}
                            className="flex items-start gap-2 mt-4"
                          >
                            <div
                              className={`h-4 w-4 rounded-full border-2 mt-2 ${
                                startCheck === 2
                                  ? "border-blue-600"
                                  : "border-gray-300"
                              }`}
                            />
                            <div>
                              <div
                                className={`w-[300px] border px-2 py-2 rounded-xl ${
                                  startCheck === 2
                                    ? EmpData.companyId === 197
                                      ? "border-[#41E6FF]"
                                      : "border-[#0752DF]"
                                    : "border-[#f2f2f2] "
                                }`}
                              >
                                <p
                                  className={`text-xs ${
                                    startCheck === 2
                                      ? EmpData.companyId === 197
                                        ? "text-[#191442]"
                                        : "text-[#0752DF]"
                                      : "text-[#A4A4A4]"
                                  }`}
                                >
                                  {EmpData.companyId === 31
                                    ? "Фид-Форвард"
                                    : "Фидбэк"}{" "}
                                  өгөх
                                </p>

                                <textarea
                                  id="feedback"
                                  name="feedback"
                                  rows="3"
                                  value={startWrite}
                                  className="mt-1 block w-full text-[12px] h-[100px]"
                                  placeholder="Энд бичнэ үү."
                                ></textarea>
                              </div>
                            </div>
                          </div>
                        </div>
                      ) : (
                        <div className="mt-4">
                          <div className="flex items-start gap-2">
                            <div
                              className={`h-4 w-4 rounded-full border-2 mt-2 ${
                                startCheck === 2
                                  ? "border-blue-600"
                                  : "border-gray-300"
                              }`}
                            />
                            <div>
                              <div
                                className={`w-[300px] border px-2 py-2 rounded-xl ${
                                  startCheck === 2
                                    ? EmpData.companyId === 197
                                      ? "border-[#41E6FF]"
                                      : "border-[#0752DF]"
                                    : "border-[#f2f2f2] "
                                }`}
                              >
                                <p
                                  className={`text-xs ${
                                    startCheck === 2
                                      ? EmpData.companyId === 197
                                        ? "text-[#191442]"
                                        : "text-[#0752DF]"
                                      : "text-[#A4A4A4]"
                                  }`}
                                >
                                  {EmpData.companyId === 31
                                    ? "Фид-Форвард"
                                    : "Фидбэк"}{" "}
                                  өгөх
                                </p>

                                <textarea
                                  id="feedback"
                                  name="feedback"
                                  onChange={(e) => {
                                    setStartWrite(e.target.value);
                                  }}
                                  rows="3"
                                  className="mt-1 block w-full text-[12px] h-[100px]"
                                  placeholder="Энд бичнэ үү."
                                  value={startWrite}
                                ></textarea>
                              </div>
                            </div>
                          </div>
                          {startWrite !== "" ? (
                            <button
                              onClick={() => {
                                setModal(3);
                                setStartCheck(1);
                              }}
                              className="w-full rounded-xl mx-3 mt-3 py-3 text-white bg-[#222] text-xs"
                            >
                              үргэлжлүүлэх
                            </button>
                          ) : (
                            <div
                              onClick={() => {
                                setStartCheck(1);
                              }}
                              className="flex items-start gap-2 mt-4"
                            >
                              <div
                                className={`h-4 w-4 rounded-full border-2 mt-2 ${
                                  startCheck === 1
                                    ? "border-blue-600"
                                    : "border-gray-300"
                                }`}
                                onClick={() => {
                                  setStartCheck(1);
                                }}
                              />
                              <div>
                                <div
                                  className={`w-[300px] border px-2 py-2 rounded-xl ${
                                    startCheck === 1
                                      ? EmpData.companyId === 197
                                        ? "border-[#41E6FF]"
                                        : "border-[#0752DF]"
                                      : "border-[#f2f2f2] "
                                  }`}
                                >
                                  <p
                                    className={`text-xs ${
                                      startCheck === 1
                                        ? EmpData.companyId === 197
                                          ? "text-[#191442]"
                                          : "text-[#0752DF]"
                                        : "text-[#A4A4A4]"
                                    }`}
                                  >
                                    Та дараах өгүүлбэрийг нөхнө үү.
                                  </p>

                                  <p className="mt-2 text-xs text-[#222] opacity-70">
                                    <span className="text-[#7278ff] opacity-100">
                                      {data.firstname}
                                    </span>{" "}
                                    та цаашид
                                    <span className="underline-gradient  font-semibold opacity-100">
                                      {" "}
                                      хэрэгжүүлж эхлэх зүйл{" "}
                                    </span>
                                    эхэлвэл
                                    <span className="underline-gradient  font-semibold opacity-100">
                                      {" "}
                                      гарах үр дүн{" "}
                                    </span>
                                    хүрч чадна.
                                  </p>
                                  <p className="text-xs text-[#222] opacity-70 my-3">
                                    Би таныг чадна гэдэгт итгэлтэй байна
                                    <span role="img" aria-label="smiley face">
                                      {" "}
                                      🤗
                                    </span>
                                  </p>
                                </div>
                              </div>
                            </div>
                          )}
                        </div>
                      )}
                    </div>
                  ) : modal === 2 ? (
                    <div>
                      <div className="flex items-center gap-2">
                        <img
                          className="h-[48px] w-[48px]"
                          src={
                            EmpData.companyId === 197
                              ? "/img/feedback/icons/bIcon2Apu.png"
                              : "/img/feedback/icons/bIcon2.svg"
                          }
                          alt="icon"
                        />
                        <p className="font-semibold">Continue</p>
                      </div>
                      <p className="text-xs opacity-70 w-[325px] mt-2">
                        Тухайн ажилтны одоо хийж байгаа бүтэмжтэй, таатай
                        хандлагыг урамшуулж цаашдаа энэхүү хандлагаа тогтоон
                        барихад туслах feedbaсk бичнэ үү.
                      </p>
                      {startCheck === 1 ? (
                        <div className="mt-4">
                          <div
                            onClick={() => {
                              setModal2(2);
                              setModal(0);
                            }}
                            className="flex items-start gap-2"
                          >
                            <div
                              className={`h-4 w-4 rounded-full border-2 mt-2 ${
                                startCheck === 1
                                  ? "border-blue-600"
                                  : "border-gray-300"
                              }`}
                            />
                            <div>
                              <div
                                className={`w-[300px] border px-2 py-2 rounded-xl ${
                                  startCheck === 1
                                    ? EmpData.companyId === 197
                                      ? "border-[#41E6FF]"
                                      : "border-[#0752DF]"
                                    : "border-[#f2f2f2] "
                                }`}
                              >
                                <p
                                  className={`text-xs ${
                                    startCheck === 1
                                      ? EmpData.companyId === 197
                                        ? "text-[#191442]"
                                        : "text-[#0752DF]"
                                      : "text-[#A4A4A4]"
                                  }`}
                                >
                                  Та дараах өгүүлбэрийг нөхнө үү.
                                </p>

                                <p className="mt-2 text-xs text-[#222] opacity-70">
                                  <span className="text-[#7278ff] opacity-100">
                                    {data.firstname}
                                  </span>{" "}
                                  та цаашид
                                  <span className="underline-gradient  font-semibold opacity-100">
                                    {" "}
                                    {continue1}{" "}
                                  </span>
                                  идэвхитэй үргэлжлүүлвэл
                                  <span className="underline-gradient  font-semibold opacity-100">
                                    {" "}
                                    {continue2}{" "}
                                  </span>
                                  чадна. Keep going!
                                  <span role="img" aria-label="smiley face">
                                    {" "}
                                    ✨
                                  </span>
                                </p>
                              </div>
                            </div>
                          </div>

                          <div
                            onClick={() => {
                              setStartCheck(2);
                            }}
                            className="flex items-start gap-2 mt-4"
                          >
                            <div
                              className={`h-4 w-4 rounded-full border-2 mt-2 ${
                                startCheck === 2
                                  ? "border-blue-600"
                                  : "border-gray-300"
                              }`}
                            />
                            <div>
                              <div
                                className={`w-[300px] border px-2 py-2 rounded-xl ${
                                  startCheck === 2
                                    ? EmpData.companyId === 197
                                      ? "border-[#41E6FF]"
                                      : "border-[#0752DF]"
                                    : "border-[#f2f2f2] "
                                }`}
                              >
                                <p
                                  className={`text-xs ${
                                    startCheck === 2
                                      ? EmpData.companyId === 197
                                        ? "text-[#191442]"
                                        : "text-[#0752DF]"
                                      : "text-[#A4A4A4]"
                                  }`}
                                >
                                  {EmpData.companyId === 31
                                    ? "Фид-Форвард"
                                    : "Фидбэк"}{" "}
                                  өгөх
                                </p>

                                <textarea
                                  id="feedback"
                                  name="feedback"
                                  rows="3"
                                  className="mt-1 block w-full text-[12px] h-[100px]"
                                  placeholder="Энд бичнэ үү."
                                  value={continueWrite}
                                ></textarea>
                              </div>
                            </div>
                          </div>
                        </div>
                      ) : (
                        <div className="mt-4">
                          <div className="flex items-start gap-2">
                            <div
                              className={`h-4 w-4 rounded-full border-2 mt-2 ${
                                startCheck === 2
                                  ? "border-blue-600"
                                  : "border-gray-300"
                              }`}
                            />
                            <div>
                              <div
                                className={`w-[300px] border px-2 py-2 rounded-xl ${
                                  startCheck === 2
                                    ? EmpData.companyId === 197
                                      ? "border-[#41E6FF]"
                                      : "border-[#0752DF]"
                                    : "border-[#f2f2f2] "
                                }`}
                              >
                                <p
                                  className={`text-xs ${
                                    startCheck === 2
                                      ? EmpData.companyId === 197
                                        ? "text-[#191442]"
                                        : "text-[#0752DF]"
                                      : "text-[#A4A4A4]"
                                  }`}
                                >
                                  {EmpData.companyId === 31
                                    ? "Фид-Форвард"
                                    : "Фидбэк"}{" "}
                                  өгөх
                                </p>

                                <textarea
                                  id="feedback"
                                  name="feedback"
                                  onChange={(e) => {
                                    setContinueWrite(e.target.value);
                                  }}
                                  rows="3"
                                  className="mt-1 block w-full text-[12px] h-[100px]"
                                  placeholder="Энд бичнэ үү."
                                  value={continueWrite}
                                ></textarea>
                              </div>
                            </div>
                          </div>
                          {continueWrite !== "" ? (
                            <button
                              onClick={() => {
                                setModal(2);
                                setStartCheck(1);
                                setPopUp(true);
                              }}
                              className="w-full rounded-xl mx-3 mt-3 py-3 text-white bg-[#222] text-xs"
                            >
                              үргэлжлүүлэх
                            </button>
                          ) : (
                            <div
                              onClick={() => {
                                setStartCheck(1);
                              }}
                              className="flex items-start gap-2 mt-4"
                            >
                              <div
                                className={`h-4 w-4 rounded-full border-2 mt-2 ${
                                  startCheck === 1
                                    ? "border-blue-600"
                                    : "border-gray-300"
                                }`}
                                onClick={() => {
                                  setStartCheck(1);
                                }}
                              />
                              <div>
                                <div
                                  className={`w-[300px] border px-2 py-2 rounded-xl ${
                                    startCheck === 1
                                      ? EmpData.companyId === 197
                                        ? "border-[#41E6FF]"
                                        : "border-[#0752DF]"
                                      : "border-[#f2f2f2] "
                                  }`}
                                >
                                  <p
                                    className={`text-xs ${
                                      startCheck === 1
                                        ? EmpData.companyId === 197
                                          ? "text-[#191442]"
                                          : "text-[#0752DF]"
                                        : "text-[#A4A4A4]"
                                    }`}
                                  >
                                    Та дараах өгүүлбэрийг нөхнө үү.
                                  </p>

                                  <p className="mt-2 text-xs text-[#222] opacity-70">
                                    <span className="text-[#7278ff] opacity-100">
                                      {data.firstname}
                                    </span>{" "}
                                    та цаашид
                                    <span className="underline-gradient  font-semibold opacity-100">
                                      {" "}
                                      үргэлжлүүлэх зүйл
                                    </span>{" "}
                                    идэвхитэй үргэлжлүүлвэл
                                    <span className="underline-gradient  font-semibold opacity-100">
                                      {" "}
                                      гарах үр дүн
                                    </span>{" "}
                                    чадна. Keep going!
                                    <span role="img" aria-label="smiley face">
                                      {" "}
                                      ✨
                                    </span>
                                  </p>
                                </div>
                              </div>
                            </div>
                          )}
                        </div>
                      )}
                    </div>
                  ) : modal === 3 ? (
                    <div>
                      <div className="flex items-center gap-2">
                        <img
                          className="h-[48px] w-[48px]"
                          src={
                            EmpData.companyId === 197
                              ? "/img/feedback/icons/bIcon3Apu.png"
                              : "/img/feedback/icons/bIcon3.svg"
                          }
                          alt="icon"
                        />
                        <p className="font-semibold">STOP</p>
                      </div>
                      <p className="text-xs opacity-70 w-[325px] mt-2">
                        Тухайн ажилтны одоо гаргаж буй таагүй, цухалдуулам
                        хандлага, үйлдлийг зогсооход чиглэсэн{" "}
                        {EmpData.companyId === 31 ? "Фид-Форвард" : "Фидбэк"}{" "}
                        өгөх ба ингэснээрээ ямар үр дүнд хүрж болохыг эелдгээр
                        илэрхийлнэ үү.
                      </p>
                      {startCheck === 1 ? (
                        <div className="mt-4">
                          <div
                            onClick={() => {
                              setModal2(3);
                              setModal(0);
                            }}
                            className="flex items-start gap-2"
                          >
                            <div
                              className={`h-4 w-4 rounded-full border-2 mt-2 ${
                                startCheck === 1
                                  ? "border-blue-600"
                                  : "border-gray-300"
                              }`}
                            />
                            <div>
                              <div
                                className={`w-[300px] border px-2 py-2 rounded-xl ${
                                  startCheck === 1
                                    ? EmpData.companyId === 197
                                      ? "border-[#41E6FF]"
                                      : "border-[#0752DF]"
                                    : "border-[#f2f2f2] "
                                }`}
                              >
                                <p
                                  className={`text-xs ${
                                    startCheck === 1
                                      ? EmpData.companyId === 197
                                        ? "text-[#191442]"
                                        : "text-[#0752DF]"
                                      : "text-[#A4A4A4]"
                                  }`}
                                >
                                  Та дараах өгүүлбэрийг нөхнө үү.
                                </p>

                                <p className="mt-2 text-xs text-[#222] opacity-70">
                                  <span className="text-[#7278ff] opacity-100">
                                    {data.firstname}
                                  </span>{" "}
                                  та цаашид
                                  <span className="underline-gradient  font-semibold opacity-100">
                                    {" "}
                                    {stop1}
                                  </span>{" "}
                                  больж чадвал
                                  <span className="underline-gradient  font-semibold opacity-100">
                                    {" "}
                                    {stop2}{" "}
                                  </span>
                                  чадна. Амжилт!
                                  <span role="img" aria-label="smiley face">
                                    {" "}
                                    😎
                                  </span>
                                </p>
                              </div>
                            </div>
                          </div>

                          <div
                            onClick={() => {
                              setStartCheck(2);
                            }}
                            className="flex items-start gap-2 mt-4"
                          >
                            <div
                              className={`h-4 w-4 rounded-full border-2 mt-2 ${
                                startCheck === 2
                                  ? "border-blue-600"
                                  : "border-gray-300"
                              }`}
                            />
                            <div>
                              <div
                                className={`w-[300px] border px-2 py-2 rounded-xl ${
                                  startCheck === 2
                                    ? EmpData.companyId === 197
                                      ? "border-[#41E6FF]"
                                      : "border-[#0752DF]"
                                    : "border-[#f2f2f2] "
                                }`}
                              >
                                <p
                                  className={`text-xs ${
                                    startCheck === 2
                                      ? EmpData.companyId === 197
                                        ? "text-[#191442]"
                                        : "text-[#0752DF]"
                                      : "text-[#A4A4A4]"
                                  }`}
                                >
                                  {EmpData.companyId === 31
                                    ? "Фид-Форвард"
                                    : "Фидбэк"}{" "}
                                  өгөх
                                </p>

                                <textarea
                                  id="feedback"
                                  name="feedback"
                                  rows="3"
                                  className="mt-1 block w-full text-[12px] h-[100px]"
                                  placeholder="Энд бичнэ үү."
                                  value={stopWrite}
                                ></textarea>
                              </div>
                            </div>
                          </div>
                        </div>
                      ) : (
                        <div className="mt-4">
                          <div className="flex items-start gap-2">
                            <div
                              className={`h-4 w-4 rounded-full border-2 mt-2 ${
                                startCheck === 2
                                  ? "border-blue-600"
                                  : "border-gray-300"
                              }`}
                            />
                            <div>
                              <div
                                className={`w-[300px] border px-2 py-2 rounded-xl ${
                                  startCheck === 2
                                    ? EmpData.companyId === 197
                                      ? "border-[#41E6FF]"
                                      : "border-[#0752DF]"
                                    : "border-[#f2f2f2] "
                                }`}
                              >
                                <p
                                  className={`text-xs ${
                                    startCheck === 2
                                      ? EmpData.companyId === 197
                                        ? "text-[#191442]"
                                        : "text-[#0752DF]"
                                      : "text-[#A4A4A4]"
                                  }`}
                                >
                                  {EmpData.companyId === 31
                                    ? "Фид-Форвард"
                                    : "Фидбэк"}{" "}
                                  өгөх
                                </p>

                                <textarea
                                  id="feedback"
                                  name="feedback"
                                  onChange={(e) => {
                                    setStopWrite(e.target.value);
                                  }}
                                  rows="3"
                                  className="mt-1 block w-full text-[12px] h-[100px]"
                                  placeholder="Энд бичнэ үү."
                                  value={stopWrite}
                                ></textarea>
                              </div>
                            </div>
                          </div>
                          {stopWrite !== "" ? (
                            <button
                              onClick={() => {
                                setModal(2);
                                setStartCheck(1);
                              }}
                              className="w-full rounded-xl mx-3 mt-3 py-3 text-white bg-[#222] text-xs"
                            >
                              үргэлжлүүлэх
                            </button>
                          ) : (
                            <div
                              onClick={() => {
                                setStartCheck(1);
                              }}
                              className="flex items-start gap-2 mt-4"
                            >
                              <div
                                className={`h-4 w-4 rounded-full border-2 mt-2 ${
                                  startCheck === 1
                                    ? "border-blue-600"
                                    : "border-gray-300"
                                }`}
                                onClick={() => {
                                  setStartCheck(1);
                                }}
                              />
                              <div>
                                <div
                                  className={`w-[300px] border px-2 py-2 rounded-xl ${
                                    startCheck === 1
                                      ? EmpData.companyId === 197
                                        ? "border-[#41E6FF]"
                                        : "border-[#0752DF]"
                                      : "border-[#f2f2f2] "
                                  }`}
                                >
                                  <p
                                    className={`text-xs ${
                                      startCheck === 1
                                        ? EmpData.companyId === 197
                                          ? "text-[#191442]"
                                          : "text-[#0752DF]"
                                        : "text-[#A4A4A4]"
                                    }`}
                                  >
                                    Та дараах өгүүлбэрийг нөхнө үү.
                                  </p>

                                  <p className="mt-2 text-xs text-[#222] opacity-70">
                                    <span className="text-[#7278ff] opacity-100">
                                      {data.firstname}
                                    </span>{" "}
                                    та цаашид
                                    <span className="underline-gradient  font-semibold opacity-100">
                                      {" "}
                                      зогсоох зүйл{" "}
                                    </span>
                                    больж чадвал
                                    <span className="underline-gradient  font-semibold opacity-100">
                                      {" "}
                                      гарах үр дүн{" "}
                                    </span>
                                    чадна. Амжилт!
                                    <span role="img" aria-label="smiley face">
                                      {" "}
                                      😎
                                    </span>
                                  </p>
                                </div>
                              </div>
                            </div>
                          )}
                        </div>
                      )}
                    </div>
                  ) : (
                    ""
                  )}
                  {modal2 !== 0 && (
                    <div>
                      <div>
                        <div>
                          <div className="flex flex-col items-center">
                            <div
                              className={`w-[300px] border px-2 py-2 rounded-xl border-[#F2F2F2]`}
                            >
                              <p className="mt-2 text-xs text-[#222] opacity-70">
                                <span className="text-[#7278ff] opacity-100">
                                  {data.firstName}
                                </span>{" "}
                                та цаашид{" "}
                                <span
                                  onClick={() => {
                                    setStartOngGoing(1);
                                  }}
                                  className={`underline-gradient  font-semibold text-[#222] cursor-pointer ${
                                    modal2 === 1
                                      ? startOnGoing === 1
                                        ? start1 === "хэрэгжүүлж эхлэх зүйл"
                                          ? "opacity-30"
                                          : "opacity-100"
                                        : "opacity-100"
                                      : modal2 === 2
                                      ? startOnGoing === 1
                                        ? continue1 === "үргэлжлүүлэх зүйл"
                                          ? "opacity-30"
                                          : "opacity-100"
                                        : "opacity-100"
                                      : modal2 === 3
                                      ? startOnGoing === 1
                                        ? stop1 === "зогсоох зүйл"
                                          ? "opacity-30"
                                          : "opacity-100"
                                        : "opacity-100"
                                      : ""
                                  } `}
                                >
                                  {modal2 === 1
                                    ? start1
                                    : modal2 === 2
                                    ? continue1
                                    : modal2 === 3
                                    ? stop1
                                    : ""}
                                </span>
                                {modal2 === 1
                                  ? " эхэлвэл"
                                  : modal2 === 2
                                  ? " идэвхитэй үргэлжлүүлвэл "
                                  : modal2 === 3
                                  ? " больж чадвал "
                                  : ""}
                                <span
                                  onClick={() => {
                                    setStartOngGoing(2);
                                  }}
                                  className={`underline-gradient  font-semibold text-[#222] cursor-pointer ${
                                    modal2 === 1
                                      ? startOnGoing === 2
                                        ? start2 === "гарах үр дүн "
                                          ? "opacity-30"
                                          : "opacity-100"
                                        : "opacity-100"
                                      : modal2 === 2
                                      ? startOnGoing === 2
                                        ? continue2 === "гарах үр дүн "
                                          ? "opacity-30"
                                          : "opacity-100"
                                        : "opacity-100"
                                      : modal2 === 3
                                      ? startOnGoing === 2
                                        ? stop2 === "гарах үр дүн "
                                          ? "opacity-30"
                                          : "opacity-100"
                                        : "opacity-100"
                                      : ""
                                  } `}
                                >
                                  {" "}
                                  {modal2 === 1
                                    ? start2
                                    : modal2 === 2
                                    ? continue2
                                    : modal2 === 3
                                    ? stop2
                                    : ""}
                                </span>
                                {modal2 === 1
                                  ? " чадна. Би таныг чадна гэдэгт итгэлтэй байна 🤗"
                                  : modal2 === 2
                                  ? " чадна. Keep going!✨ "
                                  : modal2 === 3
                                  ? " чадна. Амжилт!😎"
                                  : ""}
                              </p>
                            </div>
                          </div>
                          <p className="text-xs mt-6 px-3 mb-2">
                            Эдгээрээс сонгох болон өөрийн бодлоо бичгээр буулгах
                            боломжтой.
                          </p>
                          {startOnGoing === 1 ? (
                            <div className="px-3">
                              <p
                                onClick={() => {
                                  if (modal2 === 1) {
                                    setStart1("цаг баримтлаж");
                                    setStartOngGoing(2);
                                  } else if (modal2 === 2) {
                                    setContinue1("ажилдаа өөриймсөг ханддагаа");
                                    setStartOngGoing(2);
                                  } else if (modal2 === 3) {
                                    setStop1("хоцордгоо");
                                    setStartOngGoing(2);
                                  }
                                }}
                                className="py-0.5 px-1 rounded-xl border border-[#A7A7A7] text-xs text-[#000] opacity-70 mb-2 text-center"
                              >
                                {modal2 === 1
                                  ? "цаг баримтлаж"
                                  : modal2 === 2
                                  ? "ажилдаа өөриймсөг ханддагаа"
                                  : "хоцордгоо"}
                              </p>
                              <p
                                onClick={() => {
                                  if (modal2 === 1) {
                                    setStart1("санаагаа чөлөөтэй илэрхийлж");
                                    setStartOngGoing(2);
                                  } else if (modal2 === 2) {
                                    setContinue1(
                                      "бусдад үлгэр дуурайлал үзүүлдгээ"
                                    );
                                    setStartOngGoing(2);
                                  } else if (modal2 === 3) {
                                    setStop1("аливааг хойш тавихаа");
                                    setStartOngGoing(2);
                                  }
                                }}
                                className="py-0.5 px-1 rounded-xl border border-[#A7A7A7] text-xs text-[#000] opacity-70 mb-2  text-center"
                              >
                                {modal2 === 1
                                  ? "санаагаа чөлөөтэй илэрхийлж"
                                  : modal2 === 2
                                  ? "бусдад үлгэр дуурайлал үзүүлдгээ"
                                  : "аливааг хойш тавихаа"}
                              </p>
                              <p
                                onClick={() => {
                                  if (modal2 === 1) {
                                    setStart1("улам нээлттэй болж");
                                    setStartOngGoing(2);
                                  } else if (modal2 === 2) {
                                    setContinue1(
                                      "итгэл даахуйц, найдвартай байдгаа"
                                    );
                                    setStartOngGoing(2);
                                  } else if (modal2 === 3) {
                                    setStop1("өөрийгөө хэт өндрөөр үнэлэхээ");
                                    setStartOngGoing(2);
                                  }
                                }}
                                className="py-0.5 px-1 rounded-xl border border-[#A7A7A7] text-xs text-[#000] opacity-70 mb-2  text-center"
                              >
                                {modal2 === 1
                                  ? "улам нээлттэй болж"
                                  : modal2 === 2
                                  ? "итгэл даахуйц, найдвартай байдгаа"
                                  : "өөрийгөө хэт өндрөөр үнэлэхээ"}
                              </p>
                              <p
                                onClick={() => {
                                  if (modal2 === 1) {
                                    setStart1("бүтээмж өндөртэй ажилтан болж");
                                    setStartOngGoing(2);
                                  } else if (modal2 === 2) {
                                    setContinue1("шинийг санаачилдгаа");
                                    setStartOngGoing(2);
                                  } else if (modal2 === 3) {
                                    setStop1("өөрийгөө дутуу үнэлэхээ");
                                    setStartOngGoing(2);
                                  }
                                }}
                                className="py-0.5 px-1 rounded-xl border border-[#A7A7A7] text-xs text-[#000] opacity-70 mb-2 w-auto text-center"
                              >
                                {modal2 === 1
                                  ? "бүтээмж өндөртэй ажилтан болж"
                                  : modal2 === 2
                                  ? "шинийг санаачилдгаа"
                                  : "өөрийгөө дутуу үнэлэхээ"}
                              </p>
                            </div>
                          ) : (
                            <div className="px-3">
                              <p
                                onClick={() => {
                                  if (modal2 === 2) {
                                    setContinue2("амжилтад хүрж");
                                  } else if (modal2 === 1) {
                                    setStart2("төсөлд хувь нэмэр оруулж");
                                  } else if (modal2 === 3) {
                                    setStop2("амжилтад хүрж");
                                  }
                                }}
                                className="py-0.5 px-1 rounded-xl border border-[#A7A7A7] text-xs text-[#000] opacity-70 mb-2  text-center"
                              >
                                {modal2 === 1
                                  ? "төсөлд хувь нэмэр оруулж"
                                  : modal2 === 2
                                  ? "амжилтад хүрж"
                                  : "амжилтад хүрж"}
                              </p>
                              <p
                                onClick={() => {
                                  if (modal2 === 2) {
                                    setContinue2("төсөл амжилттай хэрэгжиж");
                                  } else if (modal2 === 1) {
                                    setStart2("төсөл амжилттай хэрэгжиж");
                                  } else if (modal2 === 3) {
                                    setStop2("төсөл амжилттай хэрэгжиж");
                                  }
                                }}
                                className="py-0.5 px-1 rounded-xl border border-[#A7A7A7] text-xs text-[#000] opacity-70 mb-2  text-center"
                              >
                                {modal2 === 1
                                  ? "төсөл амжилттай хэрэгжиж"
                                  : modal2 === 2
                                  ? "төсөл амжилттай хэрэгжиж"
                                  : "төсөл амжилттай хэрэгжиж"}
                              </p>
                              <p
                                onClick={() => {
                                  if (modal2 === 2) {
                                    setContinue2("хамт олонтойгоо дотносож");
                                  } else if (modal2 === 1) {
                                    setStart2("хамт олонтойгоо дотносож");
                                  } else if (modal2 === 3) {
                                    setStop2("хамт олонтойгоо дотносож");
                                  }
                                }}
                                className="py-0.5 px-1 rounded-xl border border-[#A7A7A7] text-xs text-[#000] opacity-70 mb-2  text-center"
                              >
                                {modal2 === 1
                                  ? "хамт олонтойгоо дотносож"
                                  : modal2 === 2
                                  ? "хамт олонтойгоо дотносож"
                                  : "хамт олонтойгоо дотносож"}
                              </p>
                              <p
                                onClick={() => {
                                  if (modal2 === 2) {
                                    setContinue2(
                                      "бүтээмж өндөртэй ажилтан болж"
                                    );
                                  } else if (modal2 === 1) {
                                    setStart2("бүтээмж өндөртэй ажилтан болж");
                                  } else if (modal2 === 3) {
                                    setStop2("бүтээмж өндөртэй ажилтан болж");
                                  }
                                }}
                                className="py-0.5 px-1 rounded-xl border border-[#A7A7A7] text-xs text-[#000] opacity-70 mb-2  text-center"
                              >
                                {modal2 === 1
                                  ? "бүтээмж өндөртэй ажилтан болж"
                                  : modal2 === 2
                                  ? "бүтээмж өндөртэй ажилтан болж"
                                  : "бүтээмж өндөртэй ажилтан болж"}
                              </p>
                            </div>
                          )}
                        </div>
                      </div>

                      <div className="relative">
                        <div
                          className="absolute top-0 left-0 right-0 h-0.5"
                          style={{
                            backgroundImage:
                              "linear-gradient(to right, #7277FF, #A55FFF)",
                          }}
                        ></div>
                        <input
                          ref={inputRef}
                          type="text"
                          value={placed}
                          placeholder={
                            startOnGoing === 1
                              ? "үргэлжлүүлэх зүйл "
                              : "гарах үр дүн"
                          }
                          onChange={(e) => {
                            if (modal2 === 1) {
                              if (startOnGoing === 1) {
                                setPlaced(e.target.value);
                                setStart1(e.target.value);
                              } else {
                                setPlaced(e.target.value);
                                setStart2(e.target.value);
                              }
                            } else if (modal2 === 2) {
                              if (startOnGoing === 1) {
                                setPlaced(e.target.value);
                                setContinue1(e.target.value);
                              } else {
                                setPlaced(e.target.value);
                                setContinue2(e.target.value);
                              }
                            } else if (modal2 === 3) {
                              if (startOnGoing === 1) {
                                setPlaced(e.target.value);
                                setStop1(e.target.value);
                              } else {
                                setPlaced(e.target.value);
                                setStop2(e.target.value);
                              }
                            }
                          }}
                          className="w-full px-4 py-2 border-none rounded-none"
                        />
                      </div>
                      <div className="py-4 flex items-center gap-2 ps-4">
                        <input
                          onClick={() => {
                            setIsHidden(!isHidden);
                          }}
                          className="w-[18px] rounded"
                          type="checkbox"
                        />
                        <p className="text-[#222]">Нэрээ нууцлах</p>
                      </div>

                      <div className="flex justify-center">
                        {modal2 === 1 ? (
                          (start1 !== "хэрэгжүүлж эхлэх зүйл" &&
                            startOnGoing === 1) ||
                          (start2 !== "гарах үр дүн" && startOnGoing === 2) ? (
                            <button
                              onClick={handleContinue}
                              className="w-full mt-4 rounded-xl mx-3 py-3 text-white bg-[#222] text-xs"
                            >
                              үргэлжлүүлэх
                            </button>
                          ) : (
                            ""
                          )
                        ) : modal2 === 2 ? (
                          (continue1 !== "үргэлжлүүлэх зүйл" &&
                            startOnGoing === 1) ||
                          (continue2 !== "гарах үр дүн" &&
                            startOnGoing === 2) ? (
                            <button
                              onClick={handleContinue}
                              className="w-full mt-4 rounded-xl mx-3 py-3 text-white bg-[#222] text-xs"
                            >
                              үргэлжлүүлэх
                            </button>
                          ) : (
                            ""
                          )
                        ) : modal2 === 3 ? (
                          (stop1 !== "зогсоох зүйл" && startOnGoing === 1) ||
                          (stop2 !== "гарах үр дүн" && startOnGoing === 2) ? (
                            <button
                              onClick={handleContinue}
                              className="w-full mt-4 rounded-xl mx-3 py-3 text-white bg-[#222] text-xs"
                            >
                              үргэлжлүүлэх
                            </button>
                          ) : (
                            ""
                          )
                        ) : (
                          ""
                        )}
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className="flex justify-end mx-6 mb-6 mt-4">
            {btn && (
              <button
                onClick={() => {
                  setPopUp(true);
                }}
                className="px-6  py-2  w-[150px]  text-sm text-white font-semibold bg-[#222] rounded-lg"
              >
                илгээх
              </button>
            )}
          </div>
        </div>{" "}
        {popUp && (
          <div
            onClick={handleModalClickOutside}
            className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50"
          >
            <div
              ref={modalRef}
              className="bg-white rounded-xl py-6 px-12 relative"
            >
              <button
                onClick={() => {
                  setPopUp(false);
                }}
                className="absolute top-2 right-3"
              >
                <IoCloseOutline className="text-2xl text-[#444]" />
              </button>
              <p className="text-center text-[#444] text-sm font-semibold mb-1">
                Та нэрээ нууцлан{" "}
                {EmpData.companyId === 31 ? "Фид-Форвард" : "Фидбэк"} илгээх гэж
                байна.
              </p>
              <p className="text-[#6B7280] text-xs text-center mb-4">
                Илгээх үү?
              </p>
              <div
                onClick={() => {
                  setIsHidden(!isHidden);
                }}
                className="py-1 flex items-center gap-1 mb-4"
              >
                <input
                  checked={isHidden}
                  onChange={() => {
                    setIsHidden(!isHidden);
                  }}
                  className="w-[16px] rounded"
                  type="checkbox"
                />

                <p className="text-[#222] text-[13px]">Нэрээ нууцлах</p>
              </div>
              <button
                className="w-full py-2 flex items-center justify-center text-white bg-[#2D2D2D] rounded-xl"
                disabled={loading}
                onClick={sendBackend}
              >
                {" "}
                {loading ? (
                  <span className="spinner">
                    <FaSpinner className="spin-animation" />
                  </span>
                ) : (
                  "Илгээх"
                )}
              </button>
            </div>
          </div>
        )}
        {popUp2 && (
          <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
            <div ref={popUpRef2} className="bg-white rounded-xl p-6 w-[400px]">
              <p className="text-sm font-semibold opacity-70 mt-5 text-center">
                {data.firstName}-д Feedback өгөх гэж буй таны анхааралд
              </p>
              <div className="flex justify-center my-4">
                {EmpData.companyId === 197 ? (
                  <img
                    className="w-auto h-[90px]"
                    src="/img/feedback/inboxIconApu.png"
                    alt="icon"
                  />
                ) : (
                  <img src="/img/feedback/inboxIcon.svg" alt="icon" />
                )}
              </div>
              <div className="mt-4">
                <div className="flex  gap-2 items-start mb-2">
                  <div className="w-max h-max">
                    <div
                      style={{
                        background: `${
                          EmpData.companyId === 197
                            ? "linear-gradient(90deg, #33EF76, #4FF98C)"
                            : "linear-gradient(90deg, #6254FF, #5B40FF, #2400FF)"
                        }`,
                      }}
                      className="w-3 h-3 mt-1 rounded-full "
                    />
                  </div>
                  <p className=" text-sm opacity-70">
                    Хүлээн авч байгаа хүндээ шууд хэрэгжүүлэх боломжтой зүйлс
                    бичээрэй.
                  </p>
                </div>
                <div className="flex gap-2  items-start mb-2">
                  <div className="w-max h-max">
                    <div
                      style={{
                        background: `${
                          EmpData.companyId === 197
                            ? "linear-gradient(90deg, #33EF76, #4FF98C)"
                            : "linear-gradient(90deg, #6254FF, #5B40FF, #2400FF)"
                        }`,
                      }}
                      className="w-3 h-3 mt-1 rounded-full "
                    />
                  </div>
                  <p className=" text-sm opacity-70">
                    Нөгөө хүнийгээ гомдоох зүйлс битгий бичээрэй.
                  </p>
                </div>
                <div className="flex gap-2  items-start mb-2">
                  <div className="w-max h-max">
                    <div
                      style={{
                        background: `${
                          EmpData.companyId === 197
                            ? "linear-gradient(90deg, #33EF76, #4FF98C)"
                            : "linear-gradient(90deg, #6254FF, #5B40FF, #2400FF)"
                        }`,
                      }}
                      className="w-3 h-3 mt-1 rounded-full "
                    />
                  </div>
                  <p className=" text-sm opacity-70">
                    Таны өгч буй feedback нь нөгөө хүнд сайжрахад нь туслах
                    зорилготой юм шүү :)
                  </p>
                </div>
                <button
                  onClick={() => {
                    setPopUp2(false);
                  }}
                  className={`w-full py-2 text-sm font-semibold text-white  ${
                    EmpData.companyId === 197 ? "bg-[#191442]" : "bg-[#2D2D2D]"
                  } rounded-xl mt-4`}
                >
                  Ойлголоо
                </button>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default DesktopFeedbackEmp;
