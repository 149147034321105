import api from "../helper/request";

const getOnboard = () => api.get("/onboard/detail");

const checkAnswer = (obj) => api.post("/onboard/answer/question", obj);

const checkedAnswer = (obj) => api.post("/onboard/answer/question", obj);

const takePicture = (file) => {
  const formData = new FormData();
  formData.append("file", file);

  return api.post("/photo-upload", formData, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
};

const getAsign = () => api.get("/onboard/assign/list");

const getAsingDetail = (id) => api.get(`/onboard/detail/assign/${id}`);

export {
  getOnboard,
  checkAnswer,
  takePicture,
  checkedAnswer,
  getAsign,
  getAsingDetail,
};
