import React, { useContext, useEffect, useRef, useState } from "react";
import { GiCheckMark } from "react-icons/gi";
import { IoIosArrowDown, IoIosArrowUp } from "react-icons/io";
import { IoMoonOutline } from "react-icons/io5";
import { LuSunDim } from "react-icons/lu";
import { IoCloseSharp } from "react-icons/io5";
import {
  checkAnswer,
  checkedAnswer,
  getAsign,
  getAsingDetail,
  getOnboard,
  takePicture,
} from "../../service/onboard.service";
import { TitleContext } from "../../context/TitleContext";
import { NightModeContext } from "../../context/NightModeContext";
import "../../style/animation.css";
import { AiOutlineLoading } from "react-icons/ai";
import Avatar from "../../components/Avatar";

const Modal = ({
  taskData,
  setPopUp,
  nightMode,
  modalDone,
  fetchOnboardData,
}) => {
  const [file, setFile] = useState(null);
  const [previewUrl, setPreviewUrl] = useState(null);
  const [error, setError] = useState("");
  const [textArea, setTextArea] = useState("");
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (modalDone && taskData) {
      setTextArea(taskData.text || "");
      setFile(taskData.img || null);
      setPreviewUrl(taskData.img || null);
    }
  }, [modalDone, taskData]);

  useEffect(() => {
    if (taskData.empPhotoUrl) {
      setPreviewUrl(taskData.empPhotoUrl);
    }

    if (taskData.empText) {
      setTextArea(taskData.empText);
    }
  }, [taskData]);

  const handleFileChange = async (e) => {
    const selectedFile = e.target.files[0];

    if (selectedFile) {
      const fileSizeInMB = selectedFile.size / (1024 * 1024);
      if (fileSizeInMB > 25) {
        setError("File size should be less than 25MB.");
        setFile(null);
        setPreviewUrl(null);
        return;
      }
      setLoading(true);
      setFile(selectedFile);
      setPreviewUrl(URL.createObjectURL(selectedFile));
      setError("");

      try {
        await takePicture(selectedFile)
          .then((data) => {
            setFile(data.data);
          })
          .catch((err) => {
            console.log(err);
          });
        setLoading(false);
      } catch (error) {
        setError("Failed to upload file. Please try again.");
        setLoading(false);
      }
    }
  };

  const handleSubmit = async () => {
    if (taskData.questionType === "TEXT" && !textArea.trim()) {
      setError("Please provide a description.");
      return;
    } else if (taskData.questionType === "IMAGE" && !file) {
      setError("Please provide an image.");
      return;
    }

    setLoading(true);

    const data = {
      questionId: taskData.id,
      isFinished: true,
      text: taskData.questionType === "TEXT" ? textArea : null,
      photoUrl: taskData.questionType === "IMAGE" ? file : null,
    };

    try {
      await checkedAnswer(data);

      setTextArea("");
      setFile(null);
      setPreviewUrl(null);
      setError("");
      fetchOnboardData();
      setPopUp(false);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      setError("Submission failed. Please try again.");
    }
  };

  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-40">
      {console.log("taskData", taskData)}
      <div
        className={`${
          nightMode ? "bg-[#2E1B5B]" : "bg-white"
        } rounded-lg px-2 py-4 w-[335px]`}
      >
        <div className="flex items-start justify-between mb-4">
          <p
            className={`${
              nightMode ? "text-white" : "text-[#444] "
            } text-xs font-semibold`}
          >
            {taskData.text}
          </p>
          <button
            onClick={() => {
              setTextArea("");
              setFile(null);
              setPreviewUrl(null);
              setPopUp(false);
            }}
            className={`${nightMode ? "text-white" : "text-[#444] "} text-lg`}
          >
            <IoCloseSharp />
          </button>
        </div>
        {taskData.questionType === "TEXT" ? (
          <div>
            <p
              className={`${
                nightMode ? "text-white" : "text-[#1E293B]"
              } text-xs mb-2`}
            >
              Тайлбар бичнэ үү.
            </p>
            <textarea
              placeholder="Энд гүйцэтгэсэн task-аа үгээр тайлбарлаж бичнэ үү."
              value={textArea}
              disabled={modalDone}
              onChange={(e) => setTextArea(e.target.value)}
              className={`border rounded-xl h-[116px]  w-full text-[10px] px-2 py-1 ${
                nightMode
                  ? "bg-[#fff] border-[#fff] bg-opacity-5 border-opacity-15 text-white"
                  : "bg-[#F8F8F8] border-[#B0B0B0] text-[#1E293B]"
              }`}
            />
          </div>
        ) : (
          <div className="mt-2">
            <p
              className={`${
                nightMode ? "text-white" : "text-[#1F2937]"
              } font-semibold text-xs`}
            >
              Зураг оруулна уу.
            </p>
            <p
              className={`${
                nightMode ? "text-white" : "text-[#6B7280] "
              } text-[10px]`}
            >
              Хийж дуусгасан task-аа зургаар баталгаажуулах.
            </p>
            <div className="flex items-center justify-center w-full">
              <div className="flex flex-col items-center justify-center w-full h-[130px] mt-4 border-2 border-[#BBBBBB] rounded-lg cursor-pointer hover:bg-gray-100 ">
                <div className="flex flex-col items-center justify-center pt-5 pb-6">
                  {previewUrl ? (
                    <img
                      className="w-full max-h-[110px] object-cover rounded"
                      src={previewUrl}
                      alt="Selected Preview"
                    />
                  ) : (
                    <>
                      <img
                        className="w-[62px] h-[62px]"
                        src="/img/onboard/input.svg"
                        alt="input"
                      />
                      <p className="mt-2 text-xs text-gray-500 dark:text-gray-400">
                        <span className="font-semibold">Click to upload</span>{" "}
                        or drag and drop
                      </p>
                      <p className="text-[10px] text-gray-500 dark:text-gray-400">
                        SVG, PNG, JPG or GIF
                      </p>
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

const TaskItem = ({ task, isLastTask, nightMode, fetchOnboardData }) => {
  const [isOpen, setIsOpen] = useState(false);
  const taskRef = useRef(null);
  const subTaskRef = useRef(null);
  const [taskHeight, setTaskHeight] = useState(0);
  const [popUp, setPopUp] = useState(false);
  const [instruction, setInstruction] = useState(false);
  const [modalData, setModalData] = useState(null);
  const [data, setData] = useState(null);
  const [subtaskHeight, setSubtaskHeight] = useState(0);
  const [modalDone, setModalDone] = useState(false);
  const [loading, setLoading] = useState(false);
  const [subtaskStates, setSubtaskStates] = useState(
    task.questions.map((subtask) => subtask.isFinish)
  );

  const handleSubmit = (file) => {
    setData(file);
  };

  useEffect(() => {
    console.log("submitted data", data);
  }, [data]);

  useEffect(() => {
    const updateTaskHeight = () => {
      let totalHeight = 10;

      if (taskRef.current) {
        totalHeight += taskRef.current.clientHeight;
      }

      if (subTaskRef.current && isOpen) {
        totalHeight += subTaskRef.current.scrollHeight;
        setSubtaskHeight(subTaskRef.current.scrollHeight);
      }

      if (subTaskRef.current && !isOpen && subtaskHeight !== 0) {
        setTimeout(() => {
          totalHeight -= subtaskHeight;
          setTaskHeight(totalHeight);
        }, 200);
      }
      setTaskHeight(totalHeight);
    };

    updateTaskHeight();

    window.addEventListener("resize", updateTaskHeight);

    return () => {
      window.removeEventListener("resize", updateTaskHeight);
    };
  }, [isOpen, subtaskHeight]);

  const getTaskStatus = () => {
    const subtasksDone = task.questions.map((subtask) => subtask.isFinish);
    const allDone = subtasksDone.every((done) => done === true);
    const anyError = subtasksDone.includes("error");
    const allfalse = subtasksDone.every((done) => done === false);
    const anyFalse = subtasksDone.includes(false);

    if (anyError) return "error";
    if (allDone) return "finished";
    if (allfalse) return "unfinished";
    if (anyFalse && subtasksDone.length > 0) return "ongoing";
  };

  const unfinishedQuestionsCount = task.questions.filter(
    (question) => !question.isFinish
  ).length;

  const taskStatus = getTaskStatus();

  const handleClicked = async (subtask, index) => {
    setLoading(true);
    setSubtaskStates((prev) =>
      prev.map((state, i) => (i === index ? !state : state))
    );
    try {
      const checking = {
        questionId: subtask.id,
        isFinished: !subtask.isFinish,
      };

      await checkAnswer(checking);

      fetchOnboardData();
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <div
        style={{ cursor: loading ? "wait" : "default" }}
        ref={taskRef}
        className={`relative w-full flex justify-between mb-4 `}
      >
        <div
          className={`relative z-20 mt-6 ${
            isLastTask ? " overflow-hidden" : ""
          }`}
        >
          {taskStatus === "finished" ? (
            <div
              className={`w-[16px] h-[16px] rounded-xl ${
                nightMode ? "bg-[#00CC99] border border-[#fff]" : "bg-[#fff]"
              } border border-[#00CC99] flex items-center justify-center relative z-20`}
            >
              <GiCheckMark
                className={`w-[10px] ${
                  nightMode ? "text-[#fff]" : "text-[#00CC99]"
                }`}
              />
            </div>
          ) : taskStatus === "error" ? (
            <div
              className={`w-[16px] h-[16px] rounded-xl ${
                nightMode ? "bg-[#FF5F6E] border border-[#fff]" : "bg-[#FFEDEF]"
              } border border-[#FF5F6E] flex items-center justify-center relative z-20`}
            >
              <img
                className={`h-[10px]`}
                src={`/img/onboard/${
                  nightMode ? "errorNight.svg" : "error.svg"
                }`}
                alt="icon"
              />
            </div>
          ) : taskStatus === "ongoing" ? (
            <div
              className={`w-[16px] h-[16px] rounded-xl ${
                nightMode ? "bg-[#FFDE2F] border border-[#fff]" : "bg-[#FFF9D8]"
              } border border-[#FFDE2F] flex items-center justify-center relative z-20`}
            >
              <img
                className="h-[10px]"
                src={`/img/onboard/${
                  nightMode ? "ongoingNight.svg" : "ongoing.svg"
                }`}
                alt="icon"
              />
            </div>
          ) : (
            <div className="w-[16px] h-[16px] rounded-xl bg-[#CECFD3] border border-[#CECFD3] flex items-center justify-center relative z-20">
              <GiCheckMark className="w-[7px] text-[#CECFD3]" />
            </div>
          )}
          {!isLastTask && (
            <div
              style={{ height: `${taskHeight}px` }}
              className={`absolute w-[6px] ${
                taskStatus === "finished"
                  ? "bg-[#00CC99]"
                  : taskStatus === "error"
                  ? "bg-[#FF5F6E]"
                  : "bg-[#EEEFF1]"
              } mt-4 left-[5px] -top-[5px] z-10`}
            />
          )}
        </div>
        <div
          className={`w-[90%] px-3 py-2 rounded-lg ${
            nightMode
              ? "border border-[#fff] border-opacity-15 bg-[#fff] bg-opacity-5"
              : "shadow-custom bg-[#fff]"
          }`}
        >
          <div
            onClick={() => setIsOpen(!isOpen)}
            className="flex items-center justify-between relative cursor-pointer"
          >
            <div className="flex items-center space-x-2">
              {/* <img src={task.img} alt="img" /> */}
              <div>
                <span
                  className={`font-semibold text-sm ${
                    nightMode ? "text-[#fff]" : "text-[#1E293B]"
                  }`}
                >
                  {task.name}{" "}
                  {taskStatus === "finished" && (
                    <span className="text-xs text-[#27AE60]">
                      {task.score}pts
                    </span>
                  )}
                </span>
                {taskStatus === "finished" ? (
                  <p
                    className={`text-xs opacity-80 ${
                      nightMode ? "text-[#fff]" : "text-[#1E293B] opacity-80"
                    }`}
                  >
                    Дуусгасан
                  </p>
                ) : taskStatus === "ongoing" ? (
                  <p
                    className={`text-xs opacity-80 ${
                      nightMode ? "text-[#fff]" : "text-[#1E293B] opacity-80"
                    }`}
                  >
                    {unfinishedQuestionsCount} таск дутуу
                  </p>
                ) : (
                  <p
                    className={`text-xs opacity-80 ${
                      nightMode ? "text-[#fff]" : "text-[#1E293B] opacity-80"
                    }`}
                  >
                    {task.score}pts
                  </p>
                )}
              </div>
            </div>
            <button
              className={`transition ${!isOpen ? "rotate-180" : "rotate-0"}`}
            >
              <IoIosArrowUp
                className={`${nightMode ? "text-[#fff]" : "text-[#1E293B]"}`}
              />
            </button>
          </div>
          <div
            ref={subTaskRef}
            className={`overflow-hidden transition-all duration-300 ease-in-out ${
              isOpen ? "max-h-[500px] opacity-100" : "max-h-0 opacity-0"
            }`}
          >
            <div className="mt-4">
              {task.questions.map((subtask, index) => {
                // Check if subtask.text is a URL
                const isUrl = (text) => {
                  const urlPattern = new RegExp(
                    "^(https?:\\/\\/)?" + // protocol
                      "((([a-zA-Z0-9\\-]+\\.)+[a-zA-Z]{2,})|" + // domain name
                      "((\\d{1,3}\\.){3}\\d{1,3}))" + // OR ip (v4) address
                      "(\\:\\d+)?(\\/[-a-zA-Z0-9@:%_\\+.~#?&//=]*)?" + // port and path
                      "(\\?[;&a-zA-Z0-9%_\\+.~#?&//=]*)?" + // query string
                      "(\\#[-a-zA-Z0-9_]*)?$",
                    "i"
                  );
                  return urlPattern.test(text);
                };

                return (
                  <div
                    onClick={() => {
                      if (subtask.questionType === "CHECK") {
                        if (!loading && !isUrl(subtask.text)) {
                          handleClicked(subtask, index); // Perform additional actions if needed
                        }
                      } else if (
                        subtask.questionType === "TEXT" ||
                        subtask.questionType === "IMAGE"
                      ) {
                        setModalData(subtask);
                        setPopUp(true);
                      }
                    }}
                    disabled={true}
                    key={index}
                    className="flex items-center cursor-pointer gap-2 w-full py-3"
                  >
                    <div
                      disabled={true}
                      onClick={() => {
                        if (isUrl(subtask.text)) {
                          handleClicked(subtask, index);
                        }
                      }}
                    >
                      {subtask.isFinish === true ? (
                        <div
                          className={`flex items-center justify-center w-[14px] h-[14px] border rounded-[2px] border-[#00CC99] ${
                            nightMode ? "bg-[#00CC99]" : "bg-[#fff]"
                          }`}
                        >
                          <GiCheckMark
                            className={`w-[10px] ${
                              nightMode ? "text-[#fff]" : "text-[#00CC99]"
                            }`}
                          />
                        </div>
                      ) : subtask.isFinish === false &&
                        taskStatus === "ongoing" ? (
                        <div
                          className={`flex items-center justify-center w-[14px] h-[14px] border rounded-[2px] border-[#E5E6E8] ${
                            nightMode ? " bg-[#E5E6E8]" : "bg-[#fff]"
                          }`}
                        ></div>
                      ) : subtask.isFinish === "error" ? (
                        <div className="flex items-center justify-center w-[14px] h-[14px] border rounded-[2px] border-[#FF5F6E] bg-[#FFEDEF]">
                          <img
                            className="h-[11px]"
                            src={`/img/onboard/${
                              nightMode ? "errorNight.svg" : "error.svg"
                            }`}
                            alt="icon"
                          />
                        </div>
                      ) : (
                        <div className="flex items-center justify-center w-[14px] h-[14px] border rounded-[2px] border-[#BCBCBC] bg-[#EBEBEB]"></div>
                      )}
                    </div>
                    <div className="w-[90%] ">
                      {isUrl(subtask.text) ? (
                        <a
                          href={subtask.text}
                          target="_blank"
                          rel="noopener noreferrer"
                          className={`text-xs underline ${
                            nightMode ? "text-[#fff]" : "text-[#2C4360]"
                          }`}
                        >
                          {subtask.text}
                        </a>
                      ) : (
                        <p
                          className={`text-xs underline ${
                            nightMode ? "text-[#fff]" : "text-[#2C4360]"
                          }`}
                        >
                          {subtask.text}
                        </p>
                      )}
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
        {instruction && (
          <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-40">
            <div
              className={`${
                nightMode ? "bg-[#2E1B5B]" : "bg-white "
              } rounded-lg px-2 py-4 w-[335px]`}
            >
              <div className="flex items-center justify-between mb-4">
                <p
                  className={`${
                    nightMode ? "text-white" : "text-[#1F2937]"
                  } text-sm font-semibold`}
                >
                  Дараах даалгаврыг гүйцэтгэхдээ:
                </p>
                <button
                  onClick={() => {
                    setInstruction(false);
                  }}
                  disabled={true}
                  className={`${
                    nightMode ? "text-white" : "text-[#1F2937]"
                  } text-lg`}
                >
                  <IoCloseSharp />
                </button>
              </div>
              <div>
                <p
                  className={`text-xs ${
                    nightMode ? "text-white" : "text-[#1F2937]"
                  }`}
                >
                  {task.instruction}
                </p>
                <button
                  onClick={() => {
                    setPopUp(true);
                    setInstruction(false);
                  }}
                  disabled={true}
                  className={`mt-4 w-full rounded-lg py-2 text-xs font-semibold ${
                    nightMode
                      ? "bg-[#fff] text-[#2E1B5B]"
                      : "bg-[#2D2D2D] text-white"
                  }`}
                >
                  Ойлголоо
                </button>
              </div>
            </div>
          </div>
        )}
        {popUp && (
          <Modal
            taskData={modalData}
            onFileSubmit={handleSubmit}
            setPopUp={setPopUp}
            nightMode={nightMode}
            modalDone={modalDone}
            fetchOnboardData={fetchOnboardData}
          />
        )}
      </div>
    </>
  );
};

const ControlOnboard = ({ nightMode, setNightMode }) => {
  const { setTitle } = useContext(TitleContext);
  const [loading, setLoading] = useState(true);
  const [onboard, setOnboard] = useState(null);
  const [coinPopUp, setCoinPopUp] = useState(false);
  const [score, setScore] = useState(0);
  const [height, setHeight] = useState(window.innerHeight - 200);
  const [isEnded, setIsEnded] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [selected, setSelected] = useState(null);
  const [assign, setAssign] = useState(null);
  const [id, setId] = useState(null);

  useEffect(() => {
    getAsign()
      .then((data) => {
        if (data.data && data.data.length > 0) {
          setId(data.data[0].onboardEmployeeId);
          setAssign(data.data);
          setSelected({
            profileUrl: data.data[0].profileUrl,
            name: `${data.data[0].lastName} ${data.data[0].firstName}`,
            position: data.data[0].position,
            onboardName: data.data[0].onboardName,
          });
        } else {
          setId(null);
          setAssign([]);
          setLoading(false);
          console.log("No assignments found.");
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const fetchOnboardData = async () => {
    try {
      const data = await getAsingDetail(id);
      setOnboard(data.data);
      const totalScore = data.data.onboardTopics.reduce((sum, item) => {
        return sum + (item.score !== null ? item.score : 0);
      }, 0);
      setLoading(false);

      setScore(totalScore);
    } catch (err) {
      console.log(err);
      setLoading(false);
    }
  };

  useEffect(() => {
    if (id) {
      fetchOnboardData();
    }
  }, [id]);

  useEffect(() => {
    const handleResize = () => {
      setHeight(window.innerHeight);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    setTitle("Onboarding");
  }, [setTitle]);

  const getFullyCompletedTasksCount = () => {
    return onboard?.onboardTopics.filter((task) =>
      task.questions.every((subtask) => subtask.isFinish === true)
    ).length;
  };

  useEffect(() => {
    const totalTasks = onboard?.onboardTopics.length;
    const completedTasks = getFullyCompletedTasksCount();

    if (completedTasks === totalTasks && totalTasks > 0) {
      setIsEnded(true);
    }
  }, [onboard?.onboardTopics]);

  if (loading) {
    return (
      <div className="h-screen w-full flex items-center justify-center">
        Loading...
      </div>
    );
  }

  if (assign.length === 0) {
    return (
      <div className="pt-20 w-full flex items-center justify-center">
        <div>
          <div className="flex justify-center mb-2">
            <img src="/img/onboard/empty.svg" alt="empty" />
          </div>
          <p className="text-center">No one asigned </p>
        </div>
      </div>
    );
  }

  const getFullyCompletedTasksScore = () => {
    return onboard.onboardTopics
      .filter((task) =>
        task.questions.every((subtask) => subtask.isFinish === true)
      )
      .reduce((sum, task) => {
        return sum + (task.score || 0);
      }, 0);
  };

  const calculateCompletionPercentage = () => {
    const totalTasks = onboard.onboardTopics.length;
    const completedTasks = getFullyCompletedTasksCount();
    return ((completedTasks / totalTasks) * 100).toFixed(0);
  };

  const handleToggle = () => {
    setNightMode(!nightMode);
  };

  const completionPercentage = calculateCompletionPercentage();

  const completedTasksScore = getFullyCompletedTasksScore();

  const toggleDropdown = () => setIsOpen(!isOpen);

  const handleSelect = (option) => {
    setSelected({
      profileUrl: option.profileUrl,
      name: `${option.lastName} ${option.firstName}`,
      position: option.position,
      onboardName: option.onboardName,
    });
    setId(option.onboardEmployeeId);
    setIsOpen(false);
  };

  return (
    <div
      style={{
        minHeight: `${height}px`,
      }}
      className={` relative ${nightMode ? "bg-[#2E1B5B]" : "bg-none"}`}
    >
      {console.log(selected)}
      <div className="pt-11 m">
        <p className="mb-4 text-[#1E293B] text-lg font-semibold">
          {selected.onboardName}
        </p>
        <div className="md:flex  block items-start justify-between mb-6 relative">
          <div className="flex items-center gap-4">
            <div
              className={`rounded-xl ${
                nightMode
                  ? "border border-[#fff] border-opacity-15 bg-[#fff] bg-opacity-5"
                  : "shadow-xl bg-[#fff]"
              } w-[100px] h-[80px] px-2 py-1 flex items-center justify-start z-20`}
            >
              <div>
                <p
                  className={`text-[9px] mb-2 text-center ${
                    nightMode ? "text-white" : "text-[#324d72]"
                  }`}
                >
                  Гүйцэтгэлийн явц
                </p>
                <div>
                  <div className="px-4 w-full bg-[#3AC267] relative rounded-xl bg-opacity-20 h-[8px]">
                    <div
                      className={`absolute top-0 left-0 h-[8px] bg-[#3AC267] rounded-xl`}
                      style={{ width: `${completionPercentage}%` }}
                    ></div>
                  </div>
                  <div className="flex items-center justify-between mt-2">
                    <div>
                      <p
                        className={`${
                          nightMode ? "text-white" : "text-[#324D72]"
                        } font-semibold text-sm`}
                      >
                        {getFullyCompletedTasksCount()}/
                        <span
                          className={`text-xs ${
                            nightMode ? "text-white" : "opacity-70"
                          }`}
                        >
                          {onboard.onboardTopics.length}
                        </span>
                      </p>
                    </div>
                    <p
                      className={` ${
                        nightMode ? "text-white" : "text-[#324d72]"
                      } text-sm font-semibold"`}
                    >
                      {calculateCompletionPercentage()}%
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div
              onClick={() => setCoinPopUp(true)}
              className={`rounded-xl cursor-pointer ${
                nightMode
                  ? "border border-[#fff] border-opacity-15 bg-[#fff] bg-opacity-5"
                  : "shadow-xl bg-[#fff]"
              } w-[100px] h-[80px] px-2 py-1 flex items-center justify-start z-20`}
            >
              <div>
                <p
                  className={`text-xs ${
                    nightMode ? "text-white" : "text-[#324d72]"
                  }`}
                >
                  Таны оноо
                </p>
                <div>
                  <img
                    className="my-1 w-[20px] h-[20px]"
                    src="/img/onboard/coin.svg"
                    alt="coin"
                  />

                  <p
                    className={`${
                      nightMode ? "text-white" : "text-[#324d72]"
                    } font-semibold text-sm`}
                  >
                    {completedTasksScore}
                    <span
                      className={`text-xs ${!nightMode ? "opacity-70" : ""}`}
                    >
                      {" "}
                      points
                    </span>
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="mt-6 md:mt-0">
            <div className="w-64 relative">
              <div
                className="flex items-center justify-between p-4 bg-white rounded-lg shadow cursor-pointer"
                onClick={toggleDropdown}
              >
                <div className="flex items-center gap-4">
                  <div className="w-[30px] h-[30px] flex items-center justify-center rounded-full overflow-hidden border border-[#cfcfcf]">
                    {selected.profileUrl ? (
                      <img
                        src={selected.profileUrl}
                        alt="profileUrl"
                        className="h-full w-full object-cover rounded-full"
                      />
                    ) : (
                      <Avatar
                        firstName={selected.firstName}
                        lastName={selected.lastName}
                      />
                    )}
                  </div>
                  <div>
                    <p className="text-xs text-[#1E293B] font-semibold">
                      {selected?.name || "Овог Нэр"}
                    </p>
                    <p className="text-xs text-[#64748B]">
                      {selected?.position || "Хэлтэс, албан тушаал"}
                    </p>
                  </div>
                </div>
                <span
                  className={`transition-transform ${
                    isOpen ? "rotate-180" : ""
                  }`}
                >
                  <IoIosArrowDown />
                </span>
              </div>
              {isOpen && (
                <div className="absolute top-full left-0 mt-2 w-full bg-white rounded-lg shadow-lg max-h-[160px] overflow-y-scroll z-30">
                  {assign
                    .filter((option) => option.onboardEmployeeId !== id)
                    .map((option) => (
                      <div
                        key={option.onboardEmployeeId}
                        className={`flex items-center p-4 gap-4 rounded-lg cursor-pointer ${
                          id === option.onboardEmployeeId
                            ? "bg-blue-100 border border-blue-400"
                            : "hover:bg-gray-100"
                        }`}
                        onClick={() => handleSelect(option)}
                      >
                        <div className="w-[30px] h-[30px] flex items-center justify-center rounded-full overflow-hidden border border-[#cfcfcf]">
                          {option.profileUrl ? (
                            <img
                              src={option.profileUrl}
                              alt="profileUrl"
                              className="w-full h-full object-cover rounded-full"
                            />
                          ) : (
                            <Avatar
                              firstName={option.firstName}
                              lastName={option.lastName}
                            />
                          )}
                        </div>

                        <div>
                          <p className="text-xs text-[#1E293B] font-semibold">
                            {option.lastName} {option.firstName}
                          </p>
                          <p className="text-xs text-[#64748B]">
                            {option.onboardName}
                          </p>
                        </div>
                      </div>
                    ))}
                </div>
              )}
            </div>
          </div>
          {nightMode && (
            <img
              className="top-4 -right-6 absolute z-10"
              src="/img/onboard/cloud.svg"
              alt="cloud"
            />
          )}
        </div>
        <div className="w-auto mb-10">
          {onboard.onboardTopics.map((task, index) => (
            <TaskItem
              key={index}
              task={task}
              isLastTask={index === onboard.onboardTopics.length - 1}
              nightMode={nightMode}
              fetchOnboardData={fetchOnboardData}
            />
          ))}
        </div>
      </div>

      {coinPopUp && (
        <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-40">
          <div
            className={`${
              nightMode ? "bg-[#2E1B5B]" : "bg-white"
            } rounded-lg px-4 py-3 w-[360px]`}
          >
            <div className="flex items-center gap-4 justify-between mb-4">
              <p
                className={`text-sm ${
                  nightMode ? "text-[#fff]" : "text-[#1E293B]"
                }`}
              >
                Оноогоо бүрэн цуглуулж {score} хүрмэгц та хүний нөөцийн
                ажилтнаас бэлгээ аваарай
              </p>
              <img src="/img/onboard/smile.svg" alt="smile" />
            </div>
            <div className="w-full rounded-md relative h-[8px] bg-[#3AC267] bg-opacity-20">
              <div
                className="h-[8px] rounded-md absolute left-0 top-0 bg-[#3AC267]"
                style={{ width: `${completionPercentage}%` }}
              ></div>
            </div>
            <div className="flex items-center justify-between mt-2">
              <div className="flex items-center gap-1">
                <img
                  className="w-[16px] h-[16px]"
                  src="/img/onboard/coin.svg"
                  alt="coing"
                />
                <p
                  className={`text-sm ${
                    nightMode ? "text-[#fff]" : "text-[#1E293B]"
                  }`}
                >
                  {completedTasksScore}
                </p>
              </div>
              <div className="flex items-center gap-1">
                <img src="/img/onboard/present.svg" alt="gift" />
                <p
                  className={`text-sm ${
                    nightMode ? "text-[#fff]" : "text-[#1E293B]"
                  }`}
                >
                  {score}
                </p>
              </div>
            </div>
            <button
              onClick={() => {
                setCoinPopUp(false);
              }}
              className={`w-full py-1 text-center rounded-lg text-xs mt-4 ${
                nightMode
                  ? "text-[#2E1B5B] bg-[#fff]"
                  : "text-[#fff] bg-[#1E293B]"
              }`}
            >
              Ойлголоо
            </button>
          </div>
        </div>
      )}

      {isEnded && (
        <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-40 px-6">
          <div className="relative flex justify-center">
            {/* Left-to-right animation */}
            <div className="absolute top-2 left-4 animate-slide-in-left">
              <p className="text-[24px] md:text-[32px] text-[#FFF]">
                Баяр хүргэе!
              </p>
              <div className="flex items-center gap-2">
                <img
                  className="w-[24px]"
                  src="/img/onboard/coin.svg"
                  alt="coin"
                />
                <p className="text-[#fff]">{completedTasksScore}</p>
              </div>
            </div>

            <img src="/img/onboard/ending/background.svg" alt="background" />
            <img
              className="absolute top-[60px] sm:top-[105px]  animate-slide-in-bottom"
              src="/img/onboard/ending/gift.svg"
              alt="gift"
            />

            <div
              className={`${
                nightMode ? "bg-[#2E1B5B]" : "bg-white"
              } absolute top-[145px] md:top-[190px] w-full rounded-b-lg px-4 py-3 opacity-0 animate-slide-in-bottom-delayed`}
            >
              <div className="flex items-center gap-2">
                <p
                  className={`text-xs ${
                    nightMode ? "text-[#fff]" : "text[#1E293B]"
                  }`}
                >
                  Та хүний нөөцийн ажилтнаас бэлгээ аваарай
                </p>
                <img src="/img/onboard/ending/smile.svg" alt="smile" />
              </div>
              <div className="flex justify-end mt-2">
                <button
                  onClick={() => {
                    setIsEnded(false);
                  }}
                  className={`text-xs py-2 px-3 rounded-md ${
                    nightMode
                      ? "text-[#1E293B] bg-[#fff]"
                      : "bg-[#1E293B] text-[#fff]"
                  }`}
                >
                  Ойлголоо
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default ControlOnboard;
