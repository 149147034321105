import React, { useContext, useEffect, useState } from "react";

import { TitleContext } from "../context/TitleContext";
import { SidebarContext } from "../context/SidebarContext";

import MobileHome from "../components/home/mobile/MobileHome";
import DesktopHome from "../components/home/desktop/DesktopHome";
import { useNavigate } from "react-router-dom";
import Modal from "../components/Modal";
import { takeBirthdays, takeMatchedHobbies } from "../service/home.service";

import Loading from "../components/common/Loading";
import { UserContext } from "../context/userContext";
import { getHobby } from "../service/user.service";

const Home = () => {
  const { setTitle } = useContext(TitleContext);
  const { setSidebar } = useContext(SidebarContext);
  const { user } = useContext(UserContext);
  const [popUp, setPopUp] = useState(true);
  const navigate = useNavigate();
  const [hobby, setHobby] = useState(null);
  const [birth, setBirth] = useState(null);
  const [num, setNum] = useState(1);
  const [hob, setHob] = useState(null);

  useEffect(() => {
    setTitle("Home Page Title");
  }, [setTitle]);

  useEffect(() => {
    // navigate("/feed-forward");
  }, [navigate]);

  useEffect(() => {
    takeBirthdays()
      .then((data) => {
        setBirth(data.data);
      })
      .catch((err) => console.log(err));
  }, []);

  useEffect(() => {
    getHobby()
      .then((data) => {
        setHob(data.data);
      })
      .catch((err) => console.log(err));
  }, []);

  useEffect(() => {
    takeMatchedHobbies()
      .then((data) => {
        setHobby(data.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  useEffect(() => {
    setSidebar("open");
  }, [setSidebar]);

  useEffect(() => {
    console.log(user);
    if (user?.hobbies?.length > 0) {
      if (user?.birthDate === null) {
        setNum(2);
      } else {
        if (user.profileUrl === null) {
          setNum(3);
        } else {
          setPopUp(false);
        }
      }
    }
  }, [user]);

  if (hobby === null || birth === null || user === null || hob === null) {
    return <Loading />;
  }

  const calculateAge = (birthDate) => {
    const birth = new Date(birthDate);
    const today = new Date();

    let age = today.getFullYear() - birth.getFullYear();
    const monthDifference = today.getMonth() - birth.getMonth();

    if (
      monthDifference < 0 ||
      (monthDifference === 0 && today.getDate() < birth.getDate())
    ) {
      age--;
    }

    return age;
  };

  const updatedData = birth.map((person) => ({
    ...person,
    age: calculateAge(person.birthDay),
  }));

  return (
    <div className="mt-11 w-10/12 m-auto">
      {popUp && <Modal num={num} modal={popUp} setModal={setPopUp} />}
      <div className="sm:hidden hidden md:block">
        <DesktopHome hob={hob} hobby={hobby} birth={updatedData} />
      </div>
      <div className="sm:block block md:hidden">
        <MobileHome hob={hob} user={user} hobby={hobby} birth={updatedData} />
      </div>
    </div>
  );
};

export default Home;
