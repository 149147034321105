import api from "../helper/request";

const getMood = () => api.get(`/employee-mood`);

const postMood = (obj) => api.post("/employee-mood", obj);

const takeMatchedHobbies = () => api.get("/employee-hobby/match");

const takeBirthdays = () => api.get("/employees/birthday");

const getHistoryMood = () => api.get("/employee-mood/time");

export { getMood, postMood, takeMatchedHobbies, takeBirthdays, getHistoryMood };
