import React from "react";

function Avatar({ firstName, lastName, style }) {
  const initials = `${lastName?.[0] || ""}${firstName?.[0] || ""}`;

  return (
    <div
      style={style}
      className="flex items-center justify-center w-full h-full bg-[#666874]  text-white font-bold rounded-full text-[6vw] md:text-[2vw]"
    >
      {initials.toUpperCase()}
    </div>
  );
}

export default Avatar;
