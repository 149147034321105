import React, { useContext, useEffect, useState } from "react";
import { IoIosArrowForward, IoIosSearch, IoMdClose } from "react-icons/io";
import { GoDotFill } from "react-icons/go";
import { MdOutlineCake } from "react-icons/md";
import { MdOutlineCameraAlt } from "react-icons/md";
import "../style/modal-animation.css";
import { createHobby, editUser, getHobby } from "../service/user.service";
import { UserContext } from "../context/userContext";
import { takePicture } from "../service/onboard.service";

const Modal = ({ num, modal, setModal }) => {
  const [hobby, setHobby] = useState(null);
  const { user, setUser, setRefresh } = useContext(UserContext);
  const [page, setPage] = useState(1);
  const [selected, setSelected] = useState([]);
  const [birthday, setBirthday] = useState(null);
  const [searchTerm, setSearchTerm] = useState("");
  const [image, setImage] = useState("/img/modal/user.svg");
  const [splash, setSplash] = useState(true);
  const [creatingLoad, setCreatingLoad] = useState(false);
  const [loadingImage, setLoadingImage] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    setPage(num);
  }, [num]);

  useEffect(() => {
    const splashTimer = setInterval(() => setSplash(false), 2000);
    return () => clearInterval(splashTimer);
  }, []);

  useEffect(() => {
    if (user?.photoUrl !== null) {
      setImage(user?.photoUrl);
    }
  }, [user]);

  useEffect(() => {
    getHobby()
      .then((data) => {
        setHobby(data.data);
      })
      .catch((err) => console.log(err));
  }, []);

  const handleImageCapture = async (e) => {
    const file = e.target.files[0];
    const maxSize = 25 * 1024 * 1024;

    if (file) {
      if (file.size > maxSize) {
        setError("File size exceeds 25MB limit.");
        setImage(null);
      } else {
        setLoadingImage(true);
        setError(null);

        try {
          const data = await takePicture(file);
          setImage(data.data);
          setLoadingImage(false);
        } catch (err) {
          setError("Failed to upload image.");
          setLoadingImage(false);
        }
      }
    }
  };

  const handleAvatarClick = (avatarSrc) => {
    setImage(avatarSrc);
  };

  const handleSelect = (item) => {
    setSelected((prevSelected) => {
      if (prevSelected.some((selectedItem) => selectedItem.id === item.id)) {
        return prevSelected.filter(
          (selectedItem) => selectedItem.id !== item.id
        );
      } else {
        return [...prevSelected, item];
      }
    });
  };

  const handleRemove = (item) => {
    setSelected(selected.filter((selectedItem) => selectedItem.id !== item.id));
  };

  const filteredItems = hobby
    ? hobby.filter((h) =>
        h.text.toLowerCase().includes(searchTerm.toLowerCase())
      )
    : [];

  const creatingHobby = async () => {
    try {
      setCreatingLoad(true);

      const selectedIds = selected.map((item) => item.id);
      const sendData = {
        employeeId: user.employeeId,
        hobby: selectedIds,
      };

      await createHobby(sendData);

      const updatedHobbies = selected.map((hobby) => ({
        id: hobby.id,
        text: hobby.text,
      }));

      setUser({
        ...user,
        hobbies: updatedHobbies,
      });

      if (user.birthDate === null) {
        setPage(2);
      } else if (user.profileUrl === null) {
        setPage(3);
      } else {
        setRefresh((prev) => !prev);
        setModal(false);
      }
    } catch (err) {
      console.error("Error while creating hobby:", err);
    } finally {
      setCreatingLoad(false);
    }
  };

  const createBirhtday = async () => {
    try {
      setCreatingLoad(true);

      const sendData = {
        birthDate: `${birthday} 00:00:00`,
      };

      await editUser(sendData);

      setUser({
        ...user,
        birthDate: sendData.birthDate,
      });

      if (user.profileUrl === null) {
        setPage(3);
      } else {
        setRefresh((prev) => !prev);
        setModal(false);
      }
    } catch (err) {
      console.error("Error while creating hobby:", err);
    } finally {
      setCreatingLoad(false);
    }
  };

  const createImage = async () => {
    try {
      setCreatingLoad(true);

      const sendData = {
        photoUrl: image,
      };

      await editUser(sendData);

      setUser({
        ...user,
        profileUrl: image,
      });
      setRefresh((prev) => !prev);

      setModal(false);
    } catch (err) {
      console.error("Error while creating hobby:", err);
    } finally {
      setCreatingLoad(false);
    }
  };

  const avatarImages = Array.from(
    { length: 40 },
    (_, i) => `/img/apu/Avatars-${String(i + 1).padStart(2, "0")}.png`
  ).filter((_, index) => index !== 25);

  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
      <div className="bg-white rounded-xl  w-[455px] md:block sm:hidden hidden">
        {page === 1 ? (
          <div className="p-6">
            <p className="text-[20px] text-[#1E293B]">
              Та ажил дээрээ өөртэйгөө сонирхол нэгтэй найзтай болмоор байна уу?
            </p>
            <p className="text-sm text-[#1E293B] mb-4">
              Тэгвэл эдгээрээс сонгоно уу.
            </p>
            <div className="relative mb-2">
              <IoIosSearch className="absolute right-4 top-2 text-[#324D72]" />
              <input
                className="text-[#324D72] text-xs px-4 py-2 border border-[#CEDAE9] rounded-xl w-full"
                placeholder="Хайх"
                type="text"
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
              />
            </div>

            {hobby === null && user ? (
              <div className="flex justify-center items-center my-4">
                <p className="text-sm text-[#324D72]">Уншиж байна...</p>
              </div>
            ) : (
              <>
                {selected.length > 0 && (
                  <div className="flex flex-wrap items-center gap-2 max-h-[400px] overflow-y-scroll my-2">
                    {selected.map((item) => (
                      <button
                        key={item.id}
                        onClick={() => handleRemove(item)}
                        className="bg-[#3D608C] px-2 py-1 rounded-lg flex items-center gap-2"
                      >
                        <p className="text-xs text-white">{item.text}</p>
                        <IoMdClose className="text-white" />
                      </button>
                    ))}
                  </div>
                )}
                <div className="flex flex-wrap items-center gap-2 max-h-[400px] overflow-y-scroll">
                  {filteredItems.map((item) => (
                    <button
                      key={item.id}
                      onClick={() => handleSelect(item)}
                      className={`px-2 py-1 rounded-lg text-xs ${
                        selected.some(
                          (selectedItem) => selectedItem.id === item.id
                        )
                          ? "bg-[#3D608C] text-[#fff]"
                          : "bg-[#F4F6FB] text-[#3D608C]"
                      }`}
                    >
                      {item.text}
                    </button>
                  ))}
                </div>
              </>
            )}
            <div className="mt-6 flex items-center justify-between">
              <button
                onClick={() => {
                  setPage(2);
                }}
                className="text-[#1E293B] text-xs"
              >
                Алгасах
              </button>
              <div className="flex items-center gap-2 ms-4">
                <GoDotFill className="text-[#1E293B]" />
                <GoDotFill className="text-[#1E293B] text-sm opacity-60" />
                <GoDotFill className="text-[#1E293B] text-sm opacity-60" />
              </div>
              <button
                onClick={creatingHobby}
                disabled={selected.length === 0 || creatingLoad}
                className={`flex items-center gap-2 ${
                  selected.length !== 0 ? "text-[#1E293B]" : "text-[#ABADB5]"
                } text-sm`}
              >
                {creatingLoad ? (
                  <span className="loader"></span>
                ) : (
                  "Үргэлжлүүлэх"
                )}
                <IoIosArrowForward />
              </button>
            </div>
          </div>
        ) : page === 2 ? (
          <div>
            <div className="pt-6 px-6">
              <p className="text-[#1E293B] text-[20px] ">
                Таны төрсөн өдөр хэзээ вэ?
              </p>
              <p className="text-[#1E293B] text-sm mb-6 ">
                Төрсөн өдрөө оруулснаар хамт олонтойгоо улам дотносох боломж
              </p>
              <div>
                <p className="text-[#293951] text-xs">
                  Төрсөн өдрөө оруулна уу.
                </p>
                <div className="relative">
                  <MdOutlineCake className="text-[#293951] opacity-70 absolute top-2.5 left-2" />
                  <input
                    value={birthday}
                    onChange={(e) => {
                      setBirthday(e.target.value);
                    }}
                    className="w-full text-xs py-2 pe-4 ps-7 border border-[#CECFD3] rounded-xl"
                    type="date"
                  />
                </div>
              </div>
            </div>
            <div className="mt-10 flex justify-end">
              <img src="/img/modal/cake.svg" alt="cake" />
            </div>
            <div className="mt-6 flex items-center justify-between px-6 pb-6">
              <button
                onClick={() => {
                  setPage(3);
                }}
                className="text-[#1E293B] text-xs"
              >
                Алгасах
              </button>
              <div className="flex items-center gap-2 ms-6">
                <GoDotFill className="text-[#1E293B] text-sm opacity-60" />
                <GoDotFill className="text-[#1E293B]" />
                <GoDotFill className="text-[#1E293B] text-sm opacity-60" />
              </div>
              <button
                onClick={createBirhtday}
                disabled={birthday === null}
                className={`flex items-center gap-2  ${
                  birthday !== null ? "text-[#1E293B]" : "text-[#ABADB5]"
                } text-sm`}
              >
                Үргэлжлүүлэх <IoIosArrowForward />
              </button>
            </div>
          </div>
        ) : page === 3 ? (
          <div className="p-6">
            <p className="text-[#1E293B] text-[20px]">Зургаа оруулна уу.</p>
            <div className="mt-6 flex items-center justify-center">
              <div className="relative w-[200px] h-[200px] ">
                <label htmlFor="imageUpload" className="cursor-pointer">
                  <img
                    src={image}
                    alt="User"
                    className="w-full h-full object-cover rounded-full"
                  />
                  <div className="absolute bottom-4 right-4 w-[36px] h-[36px] bg-[#FFFFFF] border border-[#E9EDF5] rounded-full flex items-center justify-center">
                    <MdOutlineCameraAlt className="text-xl text-[#324D72]" />
                  </div>
                </label>
                <input
                  id="imageUpload"
                  type="file"
                  accept="image/*"
                  className="hidden"
                  onChange={handleImageCapture}
                />
              </div>
            </div>
            <div className="flex items-center justify-between gap-2 my-4">
              <div className="bg-[#666666] w-[45%] h-[2px] opacity-25" />
              <p className="text-[#666666] text-sm">эсвэл</p>
              <div className="bg-[#666666] w-[45%] h-[2px] opacity-25" />
            </div>
            <div>
              <p className="text-[#1E293B] font-semibold mb-4">
                Доорх avatar-с сонгоно уу.
              </p>
              {user.companyId === 197 ? (
                <div className="w-full flex items-center gap-6 overflow-x-scroll">
                  {avatarImages.map((src, index) => (
                    <div
                      key={index}
                      onClick={() => handleAvatarClick(src)}
                      className="border border-[#EFEFEF] rounded-full cursor-pointer w-[64px] h-[64px] flex-shrink-0"
                    >
                      <img
                        className="w-full h-full rounded-full"
                        src={src}
                        alt={`Avatar ${index + 1}`}
                      />
                    </div>
                  ))}
                </div>
              ) : (
                <div className="flex items-center justify-between gap-4">
                  <div
                    className="border border-[#EFEFEF] rounded-full cursor-pointer"
                    onClick={() => handleAvatarClick("/user.svg")}
                  >
                    <img
                      src="/user.svg"
                      alt="user"
                      className="w-[64px] h-[64px]"
                    />
                  </div>
                  <div
                    className="border border-[#EFEFEF] rounded-full cursor-pointer"
                    onClick={() => handleAvatarClick("/user1.svg")}
                  >
                    <img
                      src="/user1.svg"
                      alt="user"
                      className="w-[64px] h-[64px]"
                    />
                  </div>
                  <div
                    className="border border-[#EFEFEF] rounded-full cursor-pointer"
                    onClick={() => handleAvatarClick("/user2.svg")}
                  >
                    <img
                      src="/user2.svg"
                      alt="user"
                      className="w-[64px] h-[64px]"
                    />
                  </div>
                  <div
                    className="border border-[#EFEFEF] rounded-full cursor-pointer"
                    onClick={() => handleAvatarClick("/user3.svg")}
                  >
                    <img
                      src="/user3.svg"
                      alt="user"
                      className="w-[64px] h-[64px]"
                    />
                  </div>
                </div>
              )}
            </div>
            <div className="mt-14 flex items-center justify-between px-6 ">
              <button
                onClick={() => {
                  setModal(false);
                }}
                className="text-[#1E293B] text-xs"
              >
                Алгасах
              </button>
              <div className="flex items-center gap-2 ms-6">
                <GoDotFill className="text-[#1E293B] text-sm opacity-60" />
                <GoDotFill className="text-[#1E293B] text-sm opacity-60" />
                <GoDotFill className="text-[#1E293B]" />
              </div>
              <button
                onClick={createImage}
                disabled={
                  image === "/img/modal/user.svg" ||
                  image === null ||
                  image === user.photoUrl
                }
                className={`flex items-center gap-2  ${
                  image !== "/img/modal/user.svg"
                    ? "text-[#1E293B]"
                    : "text-[#ABADB5]"
                } text-sm`}
              >
                Хадгалах <IoIosArrowForward />
              </button>
            </div>
          </div>
        ) : (
          ""
        )}
      </div>
      <div className="md:hidden block sm:block bg-[#fff] h-screen w-full relative">
        <div className="flex items-center justify-between mb-5 mt-5 mx-7">
          <div />

          <div className="flex items-center gap-2 ms-4">
            <GoDotFill
              className={`text-[#1E293B] ${page === 1 ? "" : "opacity-60"}`}
            />
            <GoDotFill
              className={`text-[#1E293B] ${page === 2 ? "" : "opacity-60"}`}
            />
            <GoDotFill
              className={`text-[#1E293B] ${page === 3 ? "" : "opacity-60"}`}
            />
          </div>
          <button
            onClick={() => {
              if (page === 1) {
                setPage(2);
              } else if (page === 2) {
                setPage(3);
              } else {
                setModal(false);
              }
            }}
            className="text-[#575763] text-sm"
          >
            Алгасах
          </button>
        </div>
        <div>
          {page === 1 ? (
            <div className="mx-7">
              <div>
                <p className="text-[#1E293B] text-[24px] mb-2 font-medium">
                  Та ажил дээрээ өөртэйгөө сонирхол нэгтэй найзтай болмоор байна
                  уу?
                </p>
                <p className="text-[#1E293B]">Тэгвэл эдгээрээс сонгоно уу.</p>
              </div>
              <div className="relative mt-4 mb-3">
                <IoIosSearch className="absolute right-4 top-2 text-[#324D72]" />
                <input
                  className="text-[#324D72] text-xs px-4 py-2 border border-[#CEDAE9] rounded-xl w-full"
                  placeholder="Хайх"
                  type="text"
                  value={searchTerm}
                  onChange={(e) => setSearchTerm(e.target.value)}
                />
              </div>
              <div className="flex flex-wrap items-center gap-2 max-h-[400px] overflow-y-scroll">
                {filteredItems.map((item, index) => (
                  <button
                    key={index}
                    onClick={() => handleSelect(item)}
                    className={`py-1 rounded-lg w-[48%] ${
                      selected.some(
                        (selectedItem) => selectedItem.id === item.id
                      )
                        ? "bg-[#3D608C] text-[#fff]"
                        : "bg-[#F4F6FB] text-[#3D608C]"
                    }`}
                  >
                    {item.text}
                  </button>
                ))}
              </div>
            </div>
          ) : page === 2 ? (
            <div className=" mx-7">
              <div className="mb-5">
                <p className="text-medium text-[24px] text-[#1E293B]">
                  Таны төрсөн өдөр хэзээ вэ?
                </p>
                <p className="text-[#1E293B]">
                  Төрсөн өдрөө оруулснаар хамт олонтойгоо улам дотносох боломж
                </p>
              </div>
              <div>
                <p className="text-[#293951] text-xs">
                  Төрсөн өдрөө оруулна уу.
                </p>
                <div className="relative">
                  <MdOutlineCake className="text-[#293951] opacity-70 absolute top-2.5 left-2" />
                  <input
                    value={birthday}
                    onChange={(e) => {
                      setBirthday(e.target.value);
                    }}
                    className="w-full text-xs py-2 pe-4 ps-7 border border-[#CECFD3] rounded-xl"
                    type="date"
                  />
                </div>
              </div>
              <div className="flex items-center justify-center mt-10">
                <img src="/img/modal/birth.svg" alt="birthday" />
              </div>
            </div>
          ) : page === 3 ? (
            <div className="mx-7">
              <div className="mb-5">
                <p className="text-medium text-[24px] text-[#1E293B]">
                  Зургаа оруулна уу.
                </p>
              </div>
              <div className="mt-6 flex items-center justify-center">
                <div className="relative w-[200px] h-[200px]">
                  <label htmlFor="imageUpload" className="cursor-pointer">
                    <img
                      src={image}
                      alt="User"
                      className={`w-full h-full object-cover rounded-full ${
                        loadingImage ? "opacity-50" : ""
                      }`}
                    />
                    <div className="absolute bottom-4 right-4 w-[36px] h-[36px] bg-[#FFFFFF] border border-[#E9EDF5] rounded-full flex items-center justify-center">
                      <MdOutlineCameraAlt className="text-xl text-[#324D72]" />
                    </div>
                  </label>
                  <input
                    id="imageUpload"
                    type="file"
                    accept="image/*"
                    className="hidden"
                    onChange={handleImageCapture}
                  />
                </div>

                {loadingImage && (
                  <div className="absolute top-0 left-0 w-full h-full flex items-center justify-center bg-[#00000080] ">
                    <div className="animate-spin border-4 border-t-4 border-t-[#324D72] border-[#E9EDF5] w-[40px] h-[40px] rounded-full"></div>
                  </div>
                )}

                {error && (
                  <div className="mt-2 text-sm text-red-500">{error}</div>
                )}
              </div>

              <div className="flex items-center justify-between gap-2 my-4">
                <div className="bg-[#666666] w-[45%] h-[2px] opacity-25" />
                <p className="text-[#666666] text-sm">эсвэл</p>
                <div className="bg-[#666666] w-[45%] h-[2px] opacity-25" />
              </div>
              <div>
                <p className="text-[#1E293B] font-semibold mb-4">
                  Доорх avatar-с сонгоно уу.
                </p>
                {user.companyId === 197 ? (
                  <div className="w-full flex items-center gap-6 overflow-x-scroll">
                    {avatarImages.map((src, index) => (
                      <div
                        key={index}
                        onClick={() => handleAvatarClick(src)}
                        className="border border-[#EFEFEF] rounded-full cursor-pointer w-[64px] h-[64px] flex-shrink-0"
                      >
                        <img
                          className="w-full h-full rounded-full"
                          src={src}
                          alt={`Avatar ${index + 1}`}
                        />
                      </div>
                    ))}
                  </div>
                ) : (
                  <div className="flex items-center justify-between gap-4">
                    <div
                      className="border border-[#EFEFEF] rounded-full cursor-pointer"
                      onClick={() => handleAvatarClick("/user.svg")}
                    >
                      <img
                        src="/user.svg"
                        alt="user"
                        className="w-[64px] h-[64px]"
                      />
                    </div>
                    <div
                      className="border border-[#EFEFEF] rounded-full cursor-pointer"
                      onClick={() => handleAvatarClick("/user1.svg")}
                    >
                      <img
                        src="/user1.svg"
                        alt="user"
                        className="w-[64px] h-[64px]"
                      />
                    </div>
                    <div
                      className="border border-[#EFEFEF] rounded-full cursor-pointer"
                      onClick={() => handleAvatarClick("/user2.svg")}
                    >
                      <img
                        src="/user2.svg"
                        alt="user"
                        className="w-[64px] h-[64px]"
                      />
                    </div>
                    <div
                      className="border border-[#EFEFEF] rounded-full cursor-pointer"
                      onClick={() => handleAvatarClick("/user3.svg")}
                    >
                      <img
                        src="/user3.svg"
                        alt="user"
                        className="w-[64px] h-[64px]"
                      />
                    </div>
                  </div>
                )}
              </div>
            </div>
          ) : (
            ""
          )}
        </div>
        <div className="bg-[#F4F6FB] w-full flex justify-end pe-4 pt-2 pb-5 absolute bottom-0">
          <button
            onClick={() => {
              if (page === 1) {
                creatingHobby();
              }
              if (page === 2) {
                createBirhtday();
              }
              if (page === 3) {
                createImage();
              }
            }}
            disabled={
              page === 1
                ? selected.length === 0 || creatingLoad
                : page === 2
                ? birthday === null
                : image === "/img/modal/user.svg" ||
                  image === null ||
                  image === user.photoUrl
            }
            className={`flex items-center gap-1 text-[#fff] bg-[#324d72] px-3 py-2 text-sm rounded-lg`}
          >
            {creatingLoad ? <span className="loader"></span> : "Үргэлжлүүлэх"}
            <IoIosArrowForward className="text-xs" />
          </button>
        </div>
      </div>
      {splash && (
        <div className="absolute w-full h-full bg-[#F4F6FB] md:hidden block sm:block"></div>
      )}
      <div className="absolute left-50% right-50% translate-y-1/2 text-ani">
        <p className="text-[#fff] text-[22px]">
          <span className="shaking">👋</span> Сайн байна уу?
        </p>
      </div>
      <div className="absolute start ">
        <img src="/img/modal/animation.png" alt="png" />
      </div>
    </div>
  );
};

export default Modal;
