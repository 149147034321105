import React, { useContext, useEffect, useRef, useState } from "react";
import { MdOutlineCake, MdOutlineEdit } from "react-icons/md";
import { MdOutlineLocalPhone } from "react-icons/md";
import { TitleContext } from "../context/TitleContext";
import { SidebarContext } from "../context/SidebarContext";
import { UserContext } from "../context/userContext";
import { FaPlus } from "react-icons/fa6";
import { IoIosClose, IoIosSearch } from "react-icons/io";
import { toast } from "react-toastify";
import { VscEye, VscEyeClosed } from "react-icons/vsc";
import { MdOutlineLock } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import {
  createHobby,
  delHobby,
  editUser,
  getHobby,
} from "../service/user.service";
import { takePicture } from "../service/onboard.service";
import { AiOutlineLoading } from "react-icons/ai";
import { getUserData } from "../service/user.service";
import Avatar from "../components/Avatar";

const MyProfile = () => {
  const { setTitle } = useContext(TitleContext);
  const { setSidebar } = useContext(SidebarContext);
  const { user, setUser, setRefresh } = useContext(UserContext);
  const navigate = useNavigate();
  const modalRef = useRef(null);

  const [socialShow, setSocialShow] = useState(false);
  const [editedOnce, setEditedOnce] = useState(false);
  const [socialLink, setSocialLink] = useState(null);
  const [igLink, setIgLink] = useState(null);
  const [linkedInLink, setLinkedInLink] = useState(null);
  const [edit, setEdit] = useState(null);

  const [newPassword, setNewPassword] = useState("");
  const [showNew, setShowNew] = useState(false);

  const [oldPassword, setOldPassword] = useState("");
  const [showOld, setShowOld] = useState(false);

  const [repeatPassword, setRepeatPassword] = useState("");
  const [showRepeat, setShowRepeat] = useState(false);

  const [phone, setPhone] = useState(0);

  const [birthday, setBirthday] = useState("");

  const [image, setImage] = useState(null);
  const [error, setError] = useState(null);

  const [modal, setModal] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [hobby, setHobby] = useState(null);
  const [selected, setSelected] = useState([]);
  const [hobLod, setHobLod] = useState(false);
  const [load, setLoad] = useState(false);

  const [loadingImage, setLoadingImage] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);

  useEffect(() => {
    setLoad(true);
    getUserData()
      .then((data) => {
        const birth = data.data.birthDate;
        const { profileUrl, phone, socialUrl, igUrl, linkedinUrl } = data.data;

        setImage(profileUrl);
        setBirthday(birth.slice(0, 10));
        setPhone(phone);
        setSocialLink(socialUrl);
        setIgLink(igUrl);
        setLinkedInLink(linkedinUrl);

        if (socialUrl || igUrl || linkedinUrl) {
          setEditedOnce(true);
        }
        setLoad(false);
      })
      .catch((err) => {
        console.log(err);
        setLoad(false);
      });
  }, []);

  useEffect(() => {
    getHobby()
      .then((data) => {
        setHobby(data.data);
      })
      .catch((err) => console.log(err));
  }, []);

  useEffect(() => {
    setTitle("Миний Profile settings");
  }, [setTitle]);

  useEffect(() => {
    setSidebar("closed");
  }, [setSidebar]);

  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (modalRef.current && !modalRef.current.contains(event.target)) {
        setModal(false);
      }
    };

    document.addEventListener("mousedown", handleOutsideClick);

    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, []);

  if (user === null || hobby === null || load) {
    return (
      <div className="flex items-center justify-center h-screen w-full">
        Loading...
      </div>
    );
  }

  const handleSelect = (item) => {
    setSelected((prevSelected) => {
      if (prevSelected.some((selectedItem) => selectedItem.id === item.id)) {
        return prevSelected.filter(
          (selectedItem) => selectedItem.id !== item.id
        );
      } else {
        return [...prevSelected, item];
      }
    });
  };

  const handleRemove = (item) => {
    setSelected(selected.filter((selectedItem) => selectedItem.id !== item.id));
  };

  const filteredItems = hobby
    ? hobby.filter((h) =>
        h.text.toLowerCase().includes(searchTerm.toLowerCase())
      )
    : [];

  const handleKeyDownSocial = (e) => {
    if (e.key === "Enter") {
      handleSaveSocial();
    }
  };

  const handleSaveSocial = async () => {
    const fb =
      user.socialUrl === socialLink
        ? null
        : socialLink.startsWith("https://")
        ? socialLink
        : `https://${socialLink}`;

    const ig =
      user.igUrl === igLink
        ? null
        : igLink.startsWith("https://")
        ? igLink
        : `${igLink}`;

    const linkedin =
      user.linkedinUrl === linkedInLink
        ? null
        : linkedInLink.startsWith("https://")
        ? linkedInLink
        : `https://${linkedInLink}`;

    try {
      const data = {
        socialUrl: fb,
        linkedinUrl: linkedin,
        igUrl: ig,
      };

      await editUser(data);
      toast.success("Амжилттай өөрчиллөө");
      setEdit(null);
      setEditedOnce(true);
      setSocialShow(!socialShow);
    } catch (err) {
      console.log("err", err);
      toast.error("Алдаа гарлаа");
    }
  };

  const handleSave = async () => {
    let bday = birthday || null;
    let number = null;
    let newPass = newPassword || null;
    let old = oldPassword || null;
    let repeat = repeatPassword || null;
    let photo = image || null;

    if (bday !== null) {
      bday = `${birthday} 00:00:00`;
    }

    if (phone) {
      if (phone.length !== 8) {
        toast.error("Утасны дугаараа зөв бичнэ үү");
        return;
      }
      number = phone;
    }

    if (newPass && old && repeat) {
      if (newPass.length < 4) {
        toast.error("Нууц үг 4-с дээш тэмдэгттэй байх ёстой");
        return;
      }
      if (newPass !== repeat) {
        toast.error("Шинэ нууц үг давтан нууц үгтэй таарахгүй байна");
        return;
      }
    }

    try {
      const data = {
        phone: number,
        birthDate: bday,
        newPassword: newPass,
        oldPassword: old,
        socialUrl: socialLink,
        linkedinUrl: linkedInLink,
        igUrl: igLink,
        photoUrl: photo,
      };

      await editUser(data);
      setRefresh((prev) => !prev);

      console.log(data);
      toast.success("Амжилттай өөрчиллөө");
      setIsModalOpen(false);
    } catch (err) {
      console.log("err", err);
      setIsModalOpen(false);
      toast.error("Алдаа гарлаа");
    }
  };

  const handleImageUpload = async (e) => {
    const file = e.target.files[0];
    const maxSize = 25 * 1024 * 1024;

    if (file) {
      if (file.size > maxSize) {
        setError("Файлын хэмжээ 25MB-аас хэтрэхгүй байх");
      } else {
        setLoadingImage(true);
        setError(null);

        try {
          const data = await takePicture(file);
          setImage(data.data);
        } catch (err) {
          setError("Failed to upload image.");
        }
        setLoadingImage(false);
      }
    }
  };

  const creatingHobby = async () => {
    setHobLod(true);
    try {
      const selectedIds = selected.map((item) => item.id);
      const sendData = {
        employeeId: user.employeeId,
        hobby: selectedIds,
      };

      await createHobby(sendData);

      const updatedUserData = await getUserData();
      const updatedHobbies = updatedUserData.data.hobbies;

      setUser({
        ...user,
        hobbies: updatedHobbies,
      });
      setRefresh((prev) => !prev);

      setModal(false);
    } catch (err) {
      console.error("Error while creating hobby:", err);
    } finally {
      setHobLod(false);
    }
  };

  const deleteHob = (id) => {
    try {
      const ids = [id];

      delHobby(ids)
        .then(() => {
          const updatedHobbies = user.hobbies.filter((data) => data.id !== id);
          setUser({ ...user, hobbies: updatedHobbies });
          setRefresh((prev) => !prev);
        })
        .catch((err) => {
          console.error("Error deleting hobby:", err);
        });
    } catch (error) {
      console.error("Unexpected error:", error);
    }
  };

  const avatarImages = Array.from(
    { length: 40 },
    (_, i) => `/img/apu/Avatars-${String(i + 1).padStart(2, "0")}.png`
  ).filter((_, index) => index !== 25);

  const handleAvatarClick = (avatarSrc) => {
    setImage(avatarSrc);
  };

  return (
    <div className="w-11/12 sm:w-11/12 md:w-10/12 m-auto pt-6 sm:pt-6 md:pt-11">
      <div className="hidden sm:hidden md:block w-full relative">
        <div className="flex gap-6">
          <div className="gap-10 mb-5 bg-[#fff] rounded-xl w-[45%] h-full shadow-custom">
            <div className="w-full px-6 py-3">
              <p className="text-[#293951] font-semibold mb-6">
                Ерөнхий мэдээлэл
              </p>
              <div className="mt-4 flex items-center gap-4">
                <div
                  className="w-16 h-16 mb-4 rounded-full overflow-hidden border border-[#cfcfcf] cursor-pointer"
                  onClick={() => setIsModalOpen(true)}
                >
                  {loadingImage && (
                    <AiOutlineLoading className="animate-spin text-blue-500 w-8 h-8 absolute top-4 left-4 m-auto" />
                  )}

                  {user.profileUrl ? (
                    <img
                      src={user.profileUrl}
                      alt="Profile"
                      className="object-cover w-full h-full"
                    />
                  ) : (
                    <Avatar
                      firstName={user.firstName}
                      lastName={user.lastName}
                    />
                  )}
                </div>

                {/* Modal */}
                {isModalOpen && (
                  <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
                    <div className="bg-white rounded-lg p-6 w-96">
                      <p className="text-lg font-semibold mb-4">
                        Зургаа оруулна уу.
                      </p>
                      <div className="flex justify-center">
                        <div
                          className="w-[150px] h-[150px] rounded-full border border-[#cfcfcf] overflow-hidden cursor-pointer relative"
                          onClick={() =>
                            document.getElementById("fileInput").click()
                          }
                        >
                          {/* Display loading spinner if image is loading */}
                          {loadingImage && (
                            <div className="absolute inset-0 flex justify-center items-center bg-white bg-opacity-50">
                              <svg
                                className="animate-spin h-8 w-8 text-blue-500"
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                              >
                                <circle
                                  className="opacity-25"
                                  cx="12"
                                  cy="12"
                                  r="10"
                                  stroke="currentColor"
                                  strokeWidth="4"
                                ></circle>
                                <path
                                  className="opacity-75"
                                  fill="currentColor"
                                  d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 2.42.878 4.63 2.291 6.291l1.709-1.709z"
                                ></path>
                              </svg>
                            </div>
                          )}

                          {/* Display uploaded image or placeholder */}
                          {image && !loadingImage ? (
                            <img
                              src={image}
                              alt="Uploaded"
                              className="w-full h-full object-cover"
                            />
                          ) : (
                            !loadingImage && (
                              <div className="flex items-center justify-center h-full text-gray-500">
                                Click to upload
                              </div>
                            )
                          )}
                        </div>

                        <input
                          type="file"
                          id="fileInput"
                          accept="image/*"
                          onChange={handleImageUpload}
                          className="hidden"
                        />
                      </div>
                      {error && (
                        <div>
                          <p className="mt-2 text-xs text-red-500">{error}</p>
                        </div>
                      )}
                      <div className="flex items-center justify-between gap-2 my-4">
                        <div className="bg-[#666666] w-[45%] h-[2px] opacity-25" />
                        <p className="text-[#666666] text-sm">эсвэл</p>
                        <div className="bg-[#666666] w-[45%] h-[2px] opacity-25" />
                      </div>
                      <div>
                        <p className="text-[#1E293B] font-semibold mb-4">
                          Доорх avatar-с сонгоно уу.
                        </p>
                        {user.companyId === 197 ? (
                          <div className="w-full flex items-center gap-6 overflow-x-scroll">
                            {avatarImages.map((src, index) => (
                              <div
                                key={index}
                                onClick={() => handleAvatarClick(src)}
                                className="border border-[#EFEFEF] rounded-full cursor-pointer w-[64px] h-[64px] flex-shrink-0"
                              >
                                <img
                                  className="w-full h-full rounded-full"
                                  src={src}
                                  alt={`Avatar ${index + 1}`}
                                />
                              </div>
                            ))}
                          </div>
                        ) : (
                          <div className="flex items-center justify-between gap-4">
                            <div
                              className="border border-[#EFEFEF] rounded-full cursor-pointer"
                              onClick={() => handleAvatarClick("/user.svg")}
                            >
                              <img
                                src="/user.svg"
                                alt="user"
                                className="w-[64px] h-[64px]"
                              />
                            </div>
                            <div
                              className="border border-[#EFEFEF] rounded-full cursor-pointer"
                              onClick={() => handleAvatarClick("/user1.svg")}
                            >
                              <img
                                src="/user1.svg"
                                alt="user"
                                className="w-[64px] h-[64px]"
                              />
                            </div>
                            <div
                              className="border border-[#EFEFEF] rounded-full cursor-pointer"
                              onClick={() => handleAvatarClick("/user2.svg")}
                            >
                              <img
                                src="/user2.svg"
                                alt="user"
                                className="w-[64px] h-[64px]"
                              />
                            </div>
                            <div
                              className="border border-[#EFEFEF] rounded-full cursor-pointer"
                              onClick={() => handleAvatarClick("/user3.svg")}
                            >
                              <img
                                src="/user3.svg"
                                alt="user"
                                className="w-[64px] h-[64px]"
                              />
                            </div>
                          </div>
                        )}
                      </div>

                      <div className="flex justify-end gap-4 mt-4">
                        <button
                          onClick={() => {
                            setImage(user.profileUrl);
                            setIsModalOpen(false);
                          }}
                          className="border rounded-lg py-2 px-4 hover:opacity-50"
                        >
                          Гарах
                        </button>
                        <button
                          className={`py-2 px-4 rounded-lg text-sm ${
                            loadingImage
                              ? "bg-gray-400 cursor-not-allowed"
                              : user?.companyId === 197
                              ? "bg-[#191442] hover:opacity-50"
                              : "bg-[#324d72] hover:opacity-50"
                          } text-white`}
                          onClick={handleSave}
                          disabled={loadingImage}
                        >
                          {loadingImage ? "Uploading..." : "Хадгалах"}{" "}
                        </button>
                      </div>
                    </div>
                  </div>
                )}

                <div className="mb-2">
                  <p className="text-[#1E293B] text-sm">
                    {user.lastName} {user.firstName}
                  </p>
                  <p className="text-[#666874] text-sm">
                    {user.departmentName}, {user.position}
                  </p>
                </div>
              </div>

              <div className="mb-4 mt-5 flex flex-col gap-3">
                <div className="flex items-center justify-between">
                  <p className="text-[#1E293B] text-xs">Мейл хаяг</p>
                  <p className="text-[#666874] text-xs">{user.email}</p>
                </div>
                <hr className="bg-[#F3F4F6]" />
                <div className="flex items-center justify-between">
                  <p className="text-[#1E293B] text-xs">
                    Ажиллаж эхэлсэн огноо
                  </p>
                  <p className="text-[#666874] text-xs">2024/04/01</p>
                </div>
                <hr className="bg-[#F3F4F6]" />
                <div className="flex items-center justify-between">
                  <p className="text-[#1E293B] text-xs">Хэлтэс</p>
                  <p className="text-[#666874] text-xs">
                    {user.departmentName}
                  </p>
                </div>
                <hr className="bg-[#F3F4F6]" />
                <div className="flex items-center justify-between">
                  <p className="text-[#1E293B] text-xs">Баг</p>
                  <p className="text-[#666874] text-xs">{user.teamName}</p>
                </div>
                {/* <hr className="bg-[#F3F4F6]" />
              <div className="flex items-center justify-between">
                <p className="text-[#1E293B] text-xs">Дээд албан тушаалтан</p>
                <p className="text-[#666874] text-xs">Employee name</p>
              </div> */}
              </div>
            </div>
            <div className="w-full px-6 py-3">
              <p className="text-[#293951] font-semibold mb-6">
                Нэмэлт мэдээлэл
              </p>
              <div>
                <div className="flex items-center gap-6 mb-4">
                  <div>
                    <label className="block text-sm text-[#293951] font-semibold mb-1">
                      Төрсөн өдөр
                    </label>
                    <div className="relative">
                      <MdOutlineCake className="text-[#293951] text-xl absolute top-1.5 left-1" />

                      <input
                        onChange={(e) => {
                          setBirthday(e.target.value);
                        }}
                        value={birthday}
                        className="rounded-md text-[#293951] text-sm border border-[#CECFD3] ps-6 pe-2 py-1 w-[160px]"
                        type="date"
                      />
                    </div>
                  </div>
                  <div>
                    <label className="block text-sm text-[#293951] font-semibold mb-1">
                      Утасны дугаар
                    </label>
                    <div className="relative">
                      <MdOutlineLocalPhone className="text-[#293951] text-xl absolute top-1.5 left-1" />

                      <input
                        onChange={(e) => {
                          setPhone(e.target.value);
                        }}
                        value={phone !== 0 ? phone : ""}
                        placeholder="9999-9999"
                        className="rounded-md text-[#293951] text-sm border border-[#CECFD3] ps-6 pe-2 py-1 w-[160px]"
                        type="number"
                      />
                    </div>
                  </div>
                </div>
                <div className="w-full border rounded-xl px-4 py-2">
                  <div className="flex items-center justify-between">
                    <p className="text-[#293951]">Social хаяг</p>
                    {!editedOnce && (
                      <button
                        onClick={() => {
                          setSocialShow(!socialShow);
                        }}
                        className=" text-[#293951]"
                      >
                        {socialShow ? (
                          <IoIosClose className="text-3xl" />
                        ) : (
                          <FaPlus className="text-xl" />
                        )}
                      </button>
                    )}
                  </div>
                  {socialShow && (
                    <div className="mt-2">
                      {user.socialUrl === null && (
                        <div className="w-full flex items-center gap-2 mb-2">
                          <img src="/img/profile/fb.svg" alt="ig" />
                          <input
                            className="w-full py-2 px-4 border rounded-md text-xs"
                            type="link"
                            placeholder="https://"
                            value={socialLink}
                            onChange={(e) => setSocialLink(e.target.value)}
                            onKeyDown={handleKeyDownSocial}
                          />
                        </div>
                      )}
                      {user.igUrl === null && (
                        <div className="w-full flex items-center gap-2 mb-2">
                          <img src="/img/profile/ig.svg" alt="ig" />
                          <input
                            className="w-full py-2 px-4 border rounded-md text-xs"
                            type="link"
                            placeholder="@"
                            value={igLink}
                            onChange={(e) => setIgLink(e.target.value)}
                            onKeyDown={handleKeyDownSocial}
                          />
                        </div>
                      )}
                      {user.linkedinUrl === null && (
                        <div className="w-full flex items-center gap-2 mb-2">
                          <img src="/img/profile/linkedin.svg" alt="ig" />
                          <input
                            className="w-full py-2 px-4 border rounded-md text-xs"
                            type="link"
                            placeholder="https://"
                            value={linkedInLink}
                            onChange={(e) => setLinkedInLink(e.target.value)}
                            onKeyDown={handleKeyDownSocial}
                          />
                        </div>
                      )}
                    </div>
                  )}
                  {!socialShow && (
                    <div>
                      {user.socialUrl && (
                        <div className="flex items-center justify-between mb-2 mt-2">
                          <div className="w-[80%] flex items-center gap-2">
                            <img src="/img/profile/fb.svg" alt="ig" />
                            {edit === "fb" ? (
                              <input
                                className="w-full py-2 px-4 border rounded-md text-xs"
                                type="link"
                                value={socialLink}
                                onChange={(e) => setSocialLink(e.target.value)}
                              />
                            ) : (
                              <p
                                onClick={() => {
                                  window.open(socialLink, "_blank");
                                }}
                                className="text-xs text-[#518CFF] underline underline-[#518CFF] cursor-pointer"
                              >
                                {socialLink}
                              </p>
                            )}
                          </div>
                          <button
                            onClick={() => {
                              if (edit === "fb") {
                                setEdit(null);
                              } else {
                                setEdit("fb");
                              }
                            }}
                          >
                            {edit === "fb" ? (
                              <IoIosClose className="text-2xl" />
                            ) : (
                              <MdOutlineEdit />
                            )}
                          </button>
                        </div>
                      )}
                      {user.igUrl && (
                        <div className="flex items-center justify-between mb-2">
                          <div className="w-[80%] flex items-center gap-2">
                            <img src="/img/profile/ig.svg" alt="ig" />
                            {edit === "ig" ? (
                              <input
                                className="w-full py-2 px-4 border rounded-md text-xs"
                                type="link"
                                value={igLink}
                                onChange={(e) => setIgLink(e.target.value)}
                              />
                            ) : (
                              <p
                                onClick={() => {
                                  window.open(
                                    `https://www.instagram.com/${igLink}/`,
                                    "_blank"
                                  );
                                }}
                                className="text-xs text-[#1E293B] cursor-pointer"
                              >
                                <span className="text-[#777985]">@ </span>
                                {igLink}
                              </p>
                            )}
                          </div>
                          <button
                            onClick={() => {
                              if (edit === "ig") {
                                setEdit(null);
                              } else {
                                setEdit("ig");
                              }
                            }}
                          >
                            {edit === "ig" ? (
                              <IoIosClose className="text-2xl" />
                            ) : (
                              <MdOutlineEdit />
                            )}
                          </button>
                        </div>
                      )}
                      {user.linkedinUrl && (
                        <div className="flex items-center justify-between mb-2">
                          <div className="w-[80%] flex items-center gap-2">
                            <img src="/img/profile/linkedin.svg" alt="ig" />
                            {edit === "linkedin" ? (
                              <input
                                className="w-full py-2 px-4 border rounded-md text-xs"
                                type="link"
                                value={linkedInLink}
                                onChange={(e) =>
                                  setLinkedInLink(e.target.value)
                                }
                              />
                            ) : (
                              <p
                                onClick={() => {
                                  window.open(linkedInLink, "_blank");
                                }}
                                className="text-xs text-[#1E293B] cursor-pointer"
                              >
                                {linkedInLink}
                              </p>
                            )}
                          </div>
                          <button
                            onClick={() => {
                              if (edit === "linkedin") {
                                setEdit(null);
                              } else {
                                setEdit("linkedin");
                              }
                            }}
                          >
                            {edit === "linkedin" ? (
                              <IoIosClose className="text-2xl" />
                            ) : (
                              <MdOutlineEdit />
                            )}
                          </button>
                        </div>
                      )}
                    </div>
                  )}
                  {editedOnce && !socialShow && (
                    <div>
                      {!socialLink || !igLink || !linkedInLink ? (
                        <button
                          onClick={() => {
                            setSocialShow(true);
                          }}
                          className="flex items-center gap-2 text-[#777985] mt-4"
                        >
                          <FaPlus />
                          <p className="text-xs">Нэмэх</p>
                        </button>
                      ) : (
                        ""
                      )}
                    </div>
                  )}

                  {socialLink !== "" &&
                    igLink !== "" &&
                    linkedInLink !== "" &&
                    socialShow && (
                      <div className="flex justify-end mt-2">
                        <button
                          onClick={handleSaveSocial}
                          className="px-5 py-2 text-[#EDEDED] bg-[#324d72] rounded-lg text-sm"
                        >
                          Хадгалах
                        </button>
                      </div>
                    )}
                  {/* {!socialShow || edit !== null ? (
                  <div className="mt-2">
                    {igLink || edit !== null ? (
                      <div className="w-full flex items-center justify-between gap-2 mb-2">
                        <div className="w-[80%] flex items-center gap-2">
                          <img src="/img/profile/ig.svg" alt="ig" />
                          {edit === "ig" ? (
                            <input
                              className="w-full py-2 px-4 border rounded-md text-xs"
                              type="link"
                              value={igLink}
                              onChange={(e) => setIgLink(e.target.value)}
                            />
                          ) : (
                            <p className="text-xs text-[#1E293B]">
                              <span className="text-[#777985]">@ </span>
                              {igLink}
                            </p>
                          )}
                        </div>
                        <button
                          onClick={() => {
                            if (edit === "ig") {
                              setEdit(null);
                              setSocialShow(false);
                            } else {
                              setEdit("ig");
                              setSocialShow(true);
                            }
                          }}
                        >
                          {edit === "ig" ? (
                            <IoIosClose className="text-2xl" />
                          ) : (
                            <MdOutlineEdit />
                          )}
                        </button>
                      </div>
                    ) : (
                      ""
                    )}
                    {socialLink || edit !== null ? (
                      <div className="w-full flex items-center justify-between gap-2 mb-2">
                        <div className="w-[80%] flex items-center gap-2">
                          <img src="/img/profile/fb.svg" alt="ig" />
                          {edit === "fb" ? (
                            <input
                              className="w-full py-2 px-4 border rounded-md text-xs"
                              type="link"
                              value={socialLink}
                              onChange={(e) => setSocialLink(e.target.value)}
                            />
                          ) : (
                            <p className="text-xs text-[#518CFF] underline underline-[#518CFF]">
                              {socialLink}
                            </p>
                          )}
                        </div>
                        <button
                          onClick={() => {
                            if (edit === "fb") {
                              setEdit(null);
                              setSocialShow(false);
                            } else {
                              setEdit("fb");
                              setSocialShow(true);
                            }
                          }}
                        >
                          {edit === "fb" ? (
                            <IoIosClose className="text-2xl" />
                          ) : (
                            <MdOutlineEdit />
                          )}
                        </button>
                      </div>
                    ) : (
                      ""
                    )}
                    {linkedInLink || edit !== null ? (
                      <div className="w-full flex items-center justify-between gap-2 mb-2">
                        <div className="w-[80%] flex items-center gap-2">
                          <img src="/img/profile/linkedin.svg" alt="ig" />
                          {edit === "linkedin" ? (
                            <input
                              className="w-full py-2 px-4 border rounded-md text-xs"
                              type="link"
                              value={linkedInLink}
                              onChange={(e) => setLinkedInLink(e.target.value)}
                            />
                          ) : (
                            <p className="text-xs text-[#1E293B]">
                              {linkedInLink}
                            </p>
                          )}
                        </div>
                        <button
                          onClick={() => {
                            if (edit === "linkedin") {
                              setEdit(null);
                              setSocialShow(false);
                            } else {
                              setEdit("linkedin");
                              setSocialShow(true);
                            }
                          }}
                        >
                          {edit === "linkedin" ? (
                            <IoIosClose className="text-2xl" />
                          ) : (
                            <MdOutlineEdit />
                          )}
                        </button>
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                ) : (
                  ""
                )}
                {(!socialShow && socialLink === null) ||
                  igLink === null ||
                  (linkedInLink === null && (
                    <div className="flex items-center gap-2 text-[#777985]">
                      <FaPlus />
                      <p className="text-xs">Нэмэх</p>
                    </div>
                  ))}
                {socialShow && (
                  <div>
                    <hr className="bg-[#F3F4F6] mb-2 mt-2" />
                    <div className="">
                      {user.igUrl === null && (
                        <div className="w-full flex items-center gap-2 mb-2">
                          <img src="/img/profile/ig.svg" alt="ig" />
                          <input
                            className="w-full py-2 px-4 border rounded-md text-xs"
                            type="link"
                            placeholder="@"
                            value={igLink}
                            onChange={(e) => setIgLink(e.target.value)}
                            onKeyDown={handleKeyDownSocial}
                          />
                        </div>
                      )}
                      {user.socialUrl === null && (
                        <div className="w-full flex items-center gap-2 mb-2">
                          <img src="/img/profile/fb.svg" alt="fb" />
                          <input
                            className="w-full py-2 px-4 border rounded-md text-xs"
                            type="link"
                            placeholder="https://"
                            value={socialLink}
                            onChange={(e) => setSocialLink(e.target.value)}
                            onKeyDown={handleKeyDownSocial}
                          />
                        </div>
                      )}

                      {user.linkedinUrl === null && (
                        <div className="w-full flex items-center gap-2 mb-2">
                          <img src="/img/profile/linkedin.svg" alt="linekdin" />
                          <input
                            className="w-full py-2 px-4 border rounded-md text-xs"
                            type="link"
                            placeholder="https://"
                            value={linkedInLink}
                            onChange={(e) => setLinkedInLink(e.target.value)}
                            onKeyDown={handleKeyDownSocial}
                          />
                        </div>
                      )}
                    </div>
                    {socialLink !== "" && socialShow && (
                      <div className="flex justify-end mt-2">
                        <button
                          onClick={handleSaveSocial}
                          className="px-5 py-2 text-[#EDEDED] bg-[#324d72] rounded-lg text-sm"
                        >
                          Хадгалах
                        </button>
                      </div>
                    )}
                  </div>
                )} */}
                </div>
              </div>
            </div>
          </div>
          <div className=" w-[55%]">
            <div className="px-8 py-5 bg-white shadow-custom rounded-xl h-[45%] mb-3">
              <div className="flex items-center justify-between">
                <p className="text-[#293951] texg-lg">Сонирхол/Хобби</p>
                <button
                  onClick={() => {
                    setModal(true);
                  }}
                  className="py-1 px-3 flex items-center gap-2 text-[#1E293B] text-sm border border-[#DEDEDE] rounded-lg"
                >
                  <FaPlus />
                  {user.hobbies.length === 0 ? "Сонирхол нэмэх" : "Нэмэх"}
                </button>
              </div>
              {user.hobbies.length === 0 ? (
                <div className="mt-6 w-full h-full">
                  <div className="flex justify-center">
                    <img src="/img/profile/empty.svg" alt="empty" />
                  </div>
                  <div className="text-center">
                    <p className="text-[#1E293B] text-sm font-semibold">
                      Мэдээлэл байхгүй байна
                    </p>
                    <p className="text-[#666874] text-xs">
                      Өөрийн хоббигоо оруулснаар өөртэйгөө адил сонирхолтой
                      ажлын хамтрагчаа олох боломж нээгдэх юм.{" "}
                    </p>
                  </div>
                </div>
              ) : (
                <div className="flex flex-wrap gap-3 max-h-[200px] mt-4 overflow-y-scroll">
                  {user.hobbies.map((item, index) => (
                    <div
                      key={index}
                      className="text-xs py-2 px-2.5 rounded-lg text-[#fff] flex items-center gap-2 bg-[#3D608C]"
                    >
                      <p>{item.text}</p>
                      <button
                        onClick={() => {
                          deleteHob(item.id);
                        }}
                      >
                        <IoIosClose className="text-lg" />
                      </button>
                    </div>
                  ))}
                </div>
              )}
            </div>
            <div className="px-8 py-5 bg-white shadow-custom rounded-xl ">
              <p className="text-[#293951] ">Нууц үг солих</p>
              <div className="flex flex-col items-start mt-4 px-20 gap-2 w-full">
                <div className="w-full">
                  <p className="text-[#293951] text-sm" htmlFor="oldPassword">
                    Одоогийн нууц үг оруулна уу.
                  </p>
                  <div className="relative">
                    <img
                      className="absolute top-1 left-2"
                      src="/img/key.svg"
                      alt="key"
                    />
                    <input
                      id="oldPassword"
                      value={oldPassword}
                      onChange={(e) => setOldPassword(e.target.value)}
                      className="w-full py-2 ps-10 pe-4 border rounded-md"
                      type={!showOld ? "password" : "text"}
                      required
                    />
                    <button
                      onClick={() => setShowOld(!showOld)}
                      className="absolute top-2.5 right-2"
                    >
                      {showOld ? (
                        <VscEyeClosed className="text-[#ABB1BB] text-xl" />
                      ) : (
                        <VscEye className="text-[#ABB1BB] text-xl" />
                      )}
                    </button>
                  </div>
                </div>
                <div className="w-full">
                  <p className="text-[#293951] text-sm">
                    Шинэ нууц үг оруулна уу.
                  </p>
                  <div className="relative">
                    <img
                      className="absolute top-1 left-2"
                      src="/img/key.svg"
                      alt="key"
                    />
                    <input
                      value={newPassword}
                      onChange={(e) => {
                        setNewPassword(e.target.value);
                      }}
                      className="w-full py-2 ps-10 pe-4 border rounded-md"
                      type={!showNew ? "password" : "text"}
                    />
                    <button
                      onClick={() => {
                        setShowNew(!showNew);
                      }}
                      className="absolute top-2.5 right-2 "
                    >
                      {showNew ? (
                        <VscEyeClosed className="text-[#ABB1BB] text-xl" />
                      ) : (
                        <VscEye className="text-[#ABB1BB] text-xl" />
                      )}
                    </button>
                  </div>
                </div>

                <div className="w-full">
                  <p className="text-[#293951] text-sm">
                    Шинэ нууц үг давтан оруулна уу.
                  </p>
                  <div className="relative">
                    <img
                      className="absolute top-1 left-2"
                      src="/img/key.svg"
                      alt="key"
                    />
                    <input
                      className="w-full py-2 ps-10 pe-4 border rounded-md"
                      value={repeatPassword}
                      onChange={(e) => {
                        setRepeatPassword(e.target.value);
                      }}
                      type={!showRepeat ? "password" : "text"}
                    />
                    <button
                      onClick={() => {
                        setShowRepeat(!showRepeat);
                      }}
                      className="absolute top-2.5 right-2 "
                    >
                      {showRepeat ? (
                        <VscEyeClosed className="text-[#ABB1BB] text-xl" />
                      ) : (
                        <VscEye className="text-[#ABB1BB] text-xl" />
                      )}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="flex justify-end gap-6 mt-4">
          <button
            onClick={() => {
              navigate("/");
            }}
            className="text-[#1E293B] border border-[#EDEDED] bg-[#fff] rounded-lg px-6 py-2"
          >
            Буцах
          </button>
          <button
            onClick={handleSave}
            className="text-[#EDEDED] bg-[#324D72] rounded-lg px-6 py-2"
          >
            Хадгалах
          </button>
        </div>
      </div>
      <div className="block sm:block md:hidden relative">
        <div className="shadow-custom p-2 rounded-lg mb-4 bg-[#fff]">
          <p className="text-[#293951] text-sm font-semibold mb-6">
            Ерөнхий мэдээлэл
          </p>
          <div className="flex items-center gap-2">
            <div className="relative">
              <div
                className="w-16 h-16 mb-4 rounded-full overflow-hidden border border-[#cfcfcf] cursor-pointer"
                onClick={() => setIsModalOpen(true)}
              >
                {loadingImage && (
                  <AiOutlineLoading className="animate-spin text-blue-500 w-8 h-8 absolute top-4 left-4 m-auto" />
                )}

                {user.profileUrl ? (
                  <img
                    src={user.profileUrl}
                    alt="Profile"
                    className="object-cover w-full h-full"
                  />
                ) : (
                  <Avatar firstName={user.firstName} lastName={user.lastName} />
                )}
              </div>

              {error && (
                <div className="absolute bottom-0 w-[300px]">
                  <p className="mt-2 text-xs text-red-500">{error}</p>
                </div>
              )}

              {isModalOpen && (
                <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
                  <div className="bg-white rounded-lg p-4 w-[280px]">
                    <p className="text-lg font-semibold mb-4">
                      Зургаа оруулна уу.
                    </p>
                    <div className="flex justify-center">
                      <div
                        className="w-[150px] h-[150px] rounded-full border border-[#cfcfcf] overflow-hidden cursor-pointer relative"
                        onClick={() =>
                          document.getElementById("fileInput").click()
                        }
                      >
                        {loadingImage && (
                          <div className="absolute inset-0 flex justify-center items-center bg-white bg-opacity-50">
                            <svg
                              className="animate-spin h-8 w-8 text-blue-500"
                              xmlns="http://www.w3.org/2000/svg"
                              fill="none"
                              viewBox="0 0 24 24"
                            >
                              <circle
                                className="opacity-25"
                                cx="12"
                                cy="12"
                                r="10"
                                stroke="currentColor"
                                strokeWidth="4"
                              ></circle>
                              <path
                                className="opacity-75"
                                fill="currentColor"
                                d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 2.42.878 4.63 2.291 6.291l1.709-1.709z"
                              ></path>
                            </svg>
                          </div>
                        )}

                        {image && !loadingImage ? (
                          <img
                            src={image}
                            alt="Uploaded"
                            className="w-full h-full object-cover"
                          />
                        ) : (
                          !loadingImage && (
                            <div className="flex items-center justify-center h-full text-gray-500">
                              Click to upload
                            </div>
                          )
                        )}
                      </div>

                      <input
                        type="file"
                        id="fileInput"
                        accept="image/*"
                        onChange={handleImageUpload}
                        className="hidden"
                      />
                    </div>
                    {error && (
                      <div className="">
                        <p className="mt-2 text-xs text-red-500">{error}</p>
                      </div>
                    )}
                    <div className="flex items-center justify-between gap-2 my-4">
                      <div className="bg-[#666666] w-[45%] h-[2px] opacity-25" />
                      <p className="text-[#666666] text-sm">эсвэл</p>
                      <div className="bg-[#666666] w-[45%] h-[2px] opacity-25" />
                    </div>
                    <div>
                      <p className="text-[#1E293B] font-semibold mb-4">
                        Доорх avatar-с сонгоно уу.
                      </p>
                      {user.companyId === 197 ? (
                        <div className="w-full flex items-center gap-6 overflow-x-scroll">
                          {avatarImages.map((src, index) => (
                            <div
                              key={index}
                              onClick={() => handleAvatarClick(src)}
                              className="border border-[#EFEFEF] rounded-full cursor-pointer w-[64px] h-[64px] flex-shrink-0"
                            >
                              <img
                                className="w-full h-full rounded-full"
                                src={src}
                                alt={`Avatar ${index + 1}`}
                              />
                            </div>
                          ))}
                        </div>
                      ) : (
                        <div className="flex items-center justify-between gap-4">
                          <div
                            className="border border-[#EFEFEF] rounded-full cursor-pointer"
                            onClick={() => handleAvatarClick("/user.svg")}
                          >
                            <img
                              src="/user.svg"
                              alt="user"
                              className="w-[64px] h-[64px]"
                            />
                          </div>
                          <div
                            className="border border-[#EFEFEF] rounded-full cursor-pointer"
                            onClick={() => handleAvatarClick("/user1.svg")}
                          >
                            <img
                              src="/user1.svg"
                              alt="user"
                              className="w-[64px] h-[64px]"
                            />
                          </div>
                          <div
                            className="border border-[#EFEFEF] rounded-full cursor-pointer"
                            onClick={() => handleAvatarClick("/user2.svg")}
                          >
                            <img
                              src="/user2.svg"
                              alt="user"
                              className="w-[64px] h-[64px]"
                            />
                          </div>
                          <div
                            className="border border-[#EFEFEF] rounded-full cursor-pointer"
                            onClick={() => handleAvatarClick("/user3.svg")}
                          >
                            <img
                              src="/user3.svg"
                              alt="user"
                              className="w-[64px] h-[64px]"
                            />
                          </div>
                        </div>
                      )}
                    </div>

                    <div className="flex justify-end gap-4 mt-4">
                      <button
                        onClick={() => {
                          setImage(user.profileUrl);
                          setIsModalOpen(false);
                        }}
                        className="border rounded-lg py-2 px-4 hover:opacity-50"
                      >
                        Гарах
                      </button>
                      <button
                        className={`py-2 px-4 rounded-lg text-sm ${
                          loadingImage
                            ? "bg-gray-400 cursor-not-allowed"
                            : user?.companyId === 197
                            ? "bg-[#191442] hover:opacity-50"
                            : "bg-[#324d72] hover:opacity-50"
                        } text-white`}
                        onClick={handleSave}
                        disabled={loadingImage}
                      >
                        {loadingImage ? "Uploading..." : "Хадгалах"}{" "}
                      </button>
                    </div>
                  </div>
                </div>
              )}
            </div>

            <div className="mb-4">
              <p className="text-[#1E293B] text-xs font-semibold">
                {user.lastName} {user.firstName}
              </p>
              <p className="text-xs text-[#666874]">{user.position}</p>
            </div>
          </div>
          <div>
            <div className="flex items-center justify-between">
              <p className="text-[#1E293B] text-xs">Мейл хаяг</p>
              <p className="text-[#1E293B] text-xs">{user.email}</p>
            </div>
            <hr className="text-[#F3F4F6] h-[1px] my-2" />

            <div className="flex items-center justify-between">
              <p className="text-[#1E293B] text-xs">Хэлтэс</p>
              <p className="text-[#1E293B] text-xs">{user.departmentName}</p>
            </div>
            <hr className="text-[#F3F4F6] h-[1px] my-2" />
            <div className="flex items-center justify-between">
              <p className="text-[#1E293B] text-xs">Баг</p>
              <p className="text-[#1E293B] text-xs">{user.teamName}</p>
            </div>
            {/* <hr className="text-[#F3F4F6] h-[1px] my-2" />
            <div className="flex items-center justify-between">
              <p className="text-[#1E293B] text-xs">Дээд албан тушаалтан</p>
              <p className="text-[#1E293B] text-xs"></p>
            </div> */}
          </div>
        </div>
        <div className="shadow-custom p-2 rounded-lg mb-4 bg-[#fff]">
          <p className="text-[#293951] text-sm mb-6">Нэмэлт мэдээлэл</p>
          <div className="flex items-center justify-between gap-4 mb-4">
            <div className="birthday-container">
              <p className="birthday-label">Төрсөн өдөр</p>
              <div className="input-wrapper">
                <MdOutlineCake className="input-icon" />
                <input
                  value={birthday}
                  onChange={(e) => {
                    setBirthday(e.target.value);
                  }}
                  className="birthday-input"
                  type="date"
                />
              </div>
            </div>

            <div className="w-[48%]">
              <p className="text-xs text-[#293951] font-semibold">
                Утасны дугаар
              </p>
              <div className="relative">
                <MdOutlineLocalPhone className="absolute top-2.5 text-[#293951] text-opacity-70 left-1" />
                <input
                  value={phone !== 0 ? phone : ""}
                  onChange={(e) => {
                    setPhone(e.target.value);
                  }}
                  placeholder="9999-9999"
                  className="w-full ps-6 py-2 border border-[#CECFD3] rounded-lg text-xs"
                  type="number"
                />
              </div>
            </div>
          </div>
          <div className="mb-4">
            <p className="text-[#293951] text-sm">Сонирхол/Хобби</p>
            <div className="flex flex-wrap gap-3 max-h-[100px] overflow-y-scroll items-center my-1">
              {user.hobbies?.map((item, index) => (
                <div
                  key={index}
                  className="text-xs py-2 px-2.5 bg-[#3D608C] text-[#fff] rounded-lg flex items-center gap-1"
                >
                  <p>{item.text}</p>
                  <button
                    onClick={() => {
                      deleteHob(item.id);
                    }}
                  >
                    <IoIosClose className="text-lg" />
                  </button>
                </div>
              ))}
            </div>
            <button
              onClick={() => {
                setModal(true);
              }}
              className="text-[#3D608C] bg-[#F4F6FB]  outline-[#CECFD3] rounded-lg py-2 px-2.5 text-xs flex items-center gap-2 mt-4 outline-dashed"
            >
              Сонирхол нэмэх
              <FaPlus />
            </button>
          </div>
          <div className="w-full border rounded-xl px-4 py-2">
            <div className="flex items-center justify-between">
              <p className="text-[#293951] text-sm">Social хаяг</p>
              {!editedOnce && (
                <button
                  onClick={() => {
                    setSocialShow(!socialShow);
                  }}
                  className=" text-[#293951]"
                >
                  {socialShow ? (
                    <IoIosClose className="text-3xl" />
                  ) : (
                    <FaPlus className="text-xl" />
                  )}
                </button>
              )}
            </div>
            {socialShow && (
              <div className="mt-2">
                {user.socialUrl === null && (
                  <div className="w-full flex items-center gap-2 mb-2">
                    <img src="/img/profile/fb.svg" alt="ig" />
                    <input
                      className="w-full py-2 px-4 border rounded-md text-xs"
                      type="link"
                      placeholder="https://"
                      value={socialLink}
                      onChange={(e) => setSocialLink(e.target.value)}
                      onKeyDown={handleKeyDownSocial}
                    />
                  </div>
                )}
                {user.igUrl === null && (
                  <div className="w-full flex items-center gap-2 mb-2">
                    <img src="/img/profile/ig.svg" alt="ig" />
                    <input
                      className="w-full py-2 px-4 border rounded-md text-xs"
                      type="link"
                      placeholder="@"
                      value={igLink}
                      onChange={(e) => setIgLink(e.target.value)}
                      onKeyDown={handleKeyDownSocial}
                    />
                  </div>
                )}
                {user.linkedinUrl === null && (
                  <div className="w-full flex items-center gap-2 mb-2">
                    <img src="/img/profile/linkedin.svg" alt="ig" />
                    <input
                      className="w-full py-2 px-4 border rounded-md text-xs"
                      type="link"
                      placeholder="https://"
                      value={linkedInLink}
                      onChange={(e) => setLinkedInLink(e.target.value)}
                      onKeyDown={handleKeyDownSocial}
                    />
                  </div>
                )}
              </div>
            )}
            {!socialShow && (
              <div>
                {user.socialUrl && (
                  <div className="flex items-center justify-between mb-2 mt-2">
                    <div className="w-[80%] flex items-center gap-2">
                      <img src="/img/profile/fb.svg" alt="ig" />
                      {edit === "fb" ? (
                        <input
                          className="w-full py-2 px-4 border rounded-md text-xs"
                          type="link"
                          value={socialLink}
                          onChange={(e) => setSocialLink(e.target.value)}
                        />
                      ) : (
                        <p
                          onClick={() => {
                            window.open(socialLink, "_blank");
                          }}
                          className="text-xs text-[#518CFF] underline underline-[#518CFF] cursor-pointer"
                        >
                          {socialLink}
                        </p>
                      )}
                    </div>
                    <button
                      onClick={() => {
                        if (edit === "fb") {
                          setEdit(null);
                        } else {
                          setEdit("fb");
                        }
                      }}
                    >
                      {edit === "fb" ? (
                        <IoIosClose className="text-2xl" />
                      ) : (
                        <MdOutlineEdit />
                      )}
                    </button>
                  </div>
                )}
                {user.igUrl && (
                  <div className="flex items-center justify-between mb-2">
                    <div className="w-[80%] flex items-center gap-2">
                      <img src="/img/profile/ig.svg" alt="ig" />
                      {edit === "ig" ? (
                        <input
                          className="w-full py-2 px-4 border rounded-md text-xs"
                          type="link"
                          value={igLink}
                          onChange={(e) => setIgLink(e.target.value)}
                        />
                      ) : (
                        <p
                          onClick={() => {
                            window.open(
                              `https://www.instagram.com/${igLink}/`,
                              "_blank"
                            );
                          }}
                          className="text-xs text-[#1E293B] cursor-pointer"
                        >
                          <span className="text-[#777985]">@ </span>
                          {igLink}
                        </p>
                      )}
                    </div>
                    <button
                      onClick={() => {
                        if (edit === "ig") {
                          setEdit(null);
                        } else {
                          setEdit("ig");
                        }
                      }}
                    >
                      {edit === "ig" ? (
                        <IoIosClose className="text-2xl" />
                      ) : (
                        <MdOutlineEdit />
                      )}
                    </button>
                  </div>
                )}
                {user.linkedinUrl && (
                  <div className="flex items-center justify-between mb-2">
                    <div className="w-[80%] flex items-center gap-2">
                      <img src="/img/profile/linkedin.svg" alt="ig" />
                      {edit === "linkedin" ? (
                        <input
                          className="w-full py-2 px-4 border rounded-md text-xs"
                          type="link"
                          value={linkedInLink}
                          onChange={(e) => setLinkedInLink(e.target.value)}
                        />
                      ) : (
                        <p
                          onClick={() => {
                            window.open(linkedInLink, "_blank");
                          }}
                          className="text-xs text-[#1E293B] cursor-pointer"
                        >
                          {linkedInLink}
                        </p>
                      )}
                    </div>
                    <button
                      onClick={() => {
                        if (edit === "linkedin") {
                          setEdit(null);
                        } else {
                          setEdit("linkedin");
                        }
                      }}
                    >
                      {edit === "linkedin" ? (
                        <IoIosClose className="text-2xl" />
                      ) : (
                        <MdOutlineEdit />
                      )}
                    </button>
                  </div>
                )}
              </div>
            )}
            {editedOnce && !socialShow && (
              <div>
                {!socialLink || !igLink || !linkedInLink ? (
                  <button
                    onClick={() => {
                      setSocialShow(true);
                    }}
                    className="flex items-center gap-2 text-[#777985] mt-4"
                  >
                    <FaPlus />
                    <p className="text-xs">Нэмэх</p>
                  </button>
                ) : (
                  ""
                )}
              </div>
            )}

            {socialLink !== "" &&
              igLink !== "" &&
              linkedInLink !== "" &&
              socialShow && (
                <div className="flex justify-end mt-4">
                  <button
                    onClick={handleSaveSocial}
                    className="px-3 py-2 text-[#EDEDED] bg-[#324d72] rounded-lg text-xs"
                  >
                    Хадгалах
                  </button>
                </div>
              )}
          </div>
        </div>
        <div className="shadow-custom p-2 rounded-lg mb-4 bg-[#fff]">
          <p className="text-[#293951] text-sm mb-6">Нууц үг солих</p>
          <div className="mb-4">
            <p className="text-[#293951] text-xs mb-1">
              Одоогийн нууц үг оруулна уу.
            </p>
            <div className="relative">
              <MdOutlineLock className="text-[#666874] absolute top-2.5 left-1" />
              <input
                className="text-xs border border-[#EFEFEF] text-[#666874] py-2 ps-6 pe-6 w-full rounded-lg"
                value={oldPassword}
                onChange={(e) => {
                  setOldPassword(e.target.value);
                }}
                type={showOld ? "text" : "password"}
              />
              <button
                onClick={() => {
                  setShowOld(!showOld);
                }}
                className="text-[#666874] absolute top-2.5 right-1 "
              >
                {" "}
                {!showOld ? <VscEye /> : <VscEyeClosed />}
              </button>
            </div>
          </div>
          <div className="mb-4">
            <p className="text-[#293951] text-xs mb-1">
              Шинэ нууц үг оруулна уу.
            </p>
            <div className="relative">
              <MdOutlineLock className="text-[#666874] absolute top-2.5 left-1" />
              <input
                value={newPassword}
                onChange={(e) => {
                  setNewPassword(e.target.value);
                }}
                className="text-xs border border-[#EFEFEF] text-[#666874] py-2 ps-6 pe-6 w-full rounded-lg"
                type={showNew ? "text" : "password"}
              />
              <button
                onClick={() => {
                  setShowNew(!showNew);
                }}
                className="text-[#666874] absolute top-2.5 right-1 "
              >
                {" "}
                {!showNew ? <VscEye /> : <VscEyeClosed />}
              </button>
            </div>
            {newPassword !== "" && newPassword.length < 4 && (
              <p className="text-[#EB5757] text-xs">
                Нууц үг 4 өөс дээш оронтой байх ёстой
              </p>
            )}
          </div>
          <div className="mb-4">
            <p className="text-[#293951] text-xs mb-1">
              Нууц үг нь 4-ээс дээш тэмдэгттэй байх ёстой
            </p>
            <div className="relative">
              <MdOutlineLock className="text-[#666874] absolute top-2.5 left-1" />
              <input
                value={repeatPassword}
                onChange={(e) => {
                  setRepeatPassword(e.target.value);
                }}
                className="text-xs border border-[#EFEFEF] text-[#666874] py-2 ps-6 pe-6 w-full rounded-lg"
                type={showRepeat ? "text" : "password"}
              />
              <button
                onClick={() => {
                  setShowRepeat(!showRepeat);
                }}
                className="text-[#666874] absolute top-2.5 right-1 "
              >
                {" "}
                {!showRepeat ? <VscEye /> : <VscEyeClosed />}
              </button>
            </div>
            {newPassword !== repeatPassword && repeatPassword !== "" && (
              <p className="text-[#EB5757] text-xs">Нууц үг таарахгүй байна</p>
            )}
          </div>
        </div>
        <div className="flex items-center justify-end mb-4 gap-4 sticky bottom-4 right-4">
          <button
            onClick={handleSave}
            className="text-[#EDEDED] text-sm py-2 px-3 bg-[#324D72] rounded-lg"
          >
            Хадгалах
          </button>
        </div>
      </div>
      {modal && (
        <div className="fixed top-0 w-full h-screen z-50 left-0 bg-[#000] bg-opacity-40 flex items-center justify-center">
          <div
            ref={modalRef}
            className="bg-[#fff] rounded-xl relative p-6 w-[320px] sm:w-[320px] md:w-[450px]"
          >
            <button
              onClick={() => {
                setModal(false);
              }}
              className="absolute top-2 right-2 text-3xl"
            >
              <IoIosClose />
            </button>
            <div>
              <p className="text-base sm:text-base md:text-[20px] text-[#1E293B] mb-2">
                Та ажил дээрээ өөртэйгөө сонирхол нэгтэй найзтай болмоор байна
                уу?
              </p>
              <p className="text-sm text-[#1E293B] mb-6">
                Тэгвэл эдгээрээс сонгоно уу.
              </p>
              <div>
                <div className="relative mb-2">
                  <IoIosSearch className="absolute right-4 top-2 text-[#324D72]" />
                  <input
                    className="text-[#324D72] text-xs px-4 py-2 border border-[#CEDAE9] rounded-xl w-full"
                    placeholder="Хайх"
                    type="text"
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                  />
                </div>
              </div>
              {selected.length > 0 && (
                <div className="flex flex-wrap items-center gap-2 max-h-[80px] overflow-y-scroll mt-2 mb-4">
                  {selected.map((item) => (
                    <button
                      key={item.id}
                      onClick={() => handleRemove(item)}
                      className="bg-[#3D608C] px-2 py-1 rounded-lg flex items-center gap-2"
                    >
                      <p className="text-xs text-white">{item.text}</p>
                      <IoIosClose className="text-white" />
                    </button>
                  ))}
                </div>
              )}
              <div className="flex flex-wrap items-center gap-2 max-h-[150px] sm:max-h-[150px] md:max-h-[300px] overflow-y-scroll">
                {filteredItems.map((item) => (
                  <button
                    key={item.id}
                    onClick={() => handleSelect(item)}
                    className={`px-2 py-1 rounded-lg text-xs ${
                      selected.some(
                        (selectedItem) => selectedItem.id === item.id
                      )
                        ? "bg-[#3D608C] text-[#fff]"
                        : "bg-[#F4F6FB] text-[#3D608C]"
                    }`}
                  >
                    {item.text}
                  </button>
                ))}
              </div>
              <div className="flex justify-end mt-4">
                <button
                  className={` py-2 px-4 text-sm rounded-lg ${
                    selected.length === 0
                      ? "bg-[#ccc] text-[#fff]"
                      : "bg-[#324d72] text-[#fff]"
                  }`}
                  onClick={creatingHobby}
                >
                  {hobLod ? "Уншиж байна..." : "Нэмэх"}
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default MyProfile;
