import React, { useEffect, useState } from "react";
import { IoIosClose, IoIosSearch } from "react-icons/io";
import { IoFilter } from "react-icons/io5";
import { FaUser } from "react-icons/fa6";
import { useNavigate } from "react-router-dom";
import Avatar from "../../Avatar";

const DesktopFeedbackSearch = ({ employee, department, user }) => {
  const [filterPopUp, setFilterPopUp] = useState(false);
  const [selectedDepartments, setSelectedDepartments] = useState([]);
  const [filteredEmployees, setFilteredEmployees] = useState(employee);
  const [departmentSearchTerm, setDepartmentSearchTerm] = useState("");
  const [employeeSearchTerm, setEmployeeSearchTerm] = useState("");
  const [firstDepName, setFirstDepName] = useState("");

  const navigate = useNavigate();

  useEffect(() => {
    if (selectedDepartments.length === 1) {
      const selected = department.find(
        (items) => items.id === selectedDepartments[0]
      );
      setFirstDepName(selected.name);
    }
  }, [selectedDepartments]);

  useEffect(() => {
    const filtered = employee.filter((emp) => {
      const matchesDepartment =
        selectedDepartments.length === 0 ||
        selectedDepartments.includes(emp.departmentId);
      const matchesName =
        emp.firstName
          .toLowerCase()
          .includes(employeeSearchTerm.toLowerCase()) ||
        emp.lastName.toLowerCase().includes(employeeSearchTerm.toLowerCase());
      return matchesDepartment && matchesName;
    });

    const employeesWithDepartmentNames = filtered.map((emp) => ({
      ...emp,
      departmentName:
        department.find((dept) => dept.id === emp.departmentId)?.name ||
        "Unknown Department",
    }));

    setFilteredEmployees(employeesWithDepartmentNames);
  }, [selectedDepartments, employeeSearchTerm, employee, department]);

  const handleDepartmentChange = (deptId) => {
    const updatedSelected = selectedDepartments.includes(deptId)
      ? selectedDepartments.filter((id) => id !== deptId)
      : [...selectedDepartments, deptId];

    setSelectedDepartments(updatedSelected);

    if (updatedSelected.length === 0) {
      setFilteredEmployees(employee);
    } else {
      const filtered = employee.filter((emp) =>
        updatedSelected.includes(emp.departmentId)
      );

      const employeesWithDepartmentNames = filtered.map((emp) => ({
        ...emp,
        departmentName:
          department.find((dept) => dept.id === emp.departmentId)?.name ||
          "Unknown Department",
      }));

      setFilteredEmployees(employeesWithDepartmentNames);
    }
  };

  const filteredDepartments = department.filter((dept) =>
    dept.name.toLowerCase().includes(departmentSearchTerm.toLowerCase())
  );

  return (
    <div className="relative">
      <div className="">
        <div className="relative mb-2">
          <input
            className="flex text-sm rounded-lg w-[200px] ps-8 pe-4 py-2 text-[#1E293B] bg-[#f4f7f6] border border-[#efefef]"
            placeholder="Ажилтны нэрийг бичнэ үү"
            type="text"
            value={employeeSearchTerm}
            onChange={(e) => setEmployeeSearchTerm(e.target.value)}
          />
          <IoIosSearch className="text-xl absolute top-2.5 left-2 text-[#1E293B] opacity-80" />
        </div>
        <button
          onClick={() => {
            setFilterPopUp(true);
          }}
          className="px-4 py-2 bg-[#f4f7f6] rounded-lg flex items-center gap-2 border border-[#efefef] max-w-[160px]"
        >
          <IoFilter className="text-md" />
          <p
            className="text-sm text-[#1E293B]"
            style={{
              overflow: "hidden",
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
              maxWidth: "100px",
            }}
          >
            {selectedDepartments.length === 1 ? firstDepName : "Filter"}
          </p>
        </button>
      </div>
      <div className="mt-10 flex ">
        <div className="flex flex-wrap items-start gap-2 justify-start">
          {filteredEmployees.map((items) => {
            return (
              <div
                key={items.id}
                onClick={() => {
                  navigate(`/feed-forward/${items.id}`);
                }}
                className="flex items-center mb-6 bg-[#fff] px-4 py-2 rounded-xl h-[110px] w-full gap-4 shadow-custom cursor-pointer"
              >
                <div>
                  <div className="w-[60px] h-[60px] rounded-full border border-[#ccc]">
                    {items.profileUrl ? (
                      <img
                        className="w-full h-full object-cover rounded-full"
                        src={items.profileUrl}
                        alt="aaa"
                      />
                    ) : (
                      <Avatar
                        firstName={items.firstName}
                        lastName={items.lastName}
                      />
                    )}
                  </div>
                </div>
                <div>
                  <p
                    className={`text-sm ${
                      user.companyId === 197
                        ? "text-[#191442]"
                        : "text-[#1E293B] "
                    } font-semibold`}
                  >
                    {items.lastName} {items.firstName}
                  </p>
                  <p className="mt-2 text-xs text-[#666874]">
                    {items.departmentName}, {items.position}
                  </p>
                  <p className="text-xs text-[#666874]">{items.email}</p>
                </div>
              </div>
            );
          })}
        </div>
      </div>
      {filterPopUp && (
        <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-40">
          <div className="bg-[#fff] rounded-xl px-6 py-2 w-[300px]">
            <div className="flex items-center justify-between mb-2">
              <p className="font-semibold text-[#1E293B] text-lg ">
                Хэлтэсүүд:
              </p>
              <button
                onClick={() => {
                  setFilterPopUp(false);
                }}
                className="text-4xl"
              >
                <IoIosClose />
              </button>
            </div>
            <div className="mb-6 ">
              <input
                className="border border-[#efefef] text-xs px-3 py-2 rounded-lg w-max"
                type="text"
                placeholder="Хэлтсийн нэрээр нь хайх"
                value={departmentSearchTerm}
                onChange={(e) => setDepartmentSearchTerm(e.target.value)}
              />
              <div className="flex items-center gap-2 mt-2 flex-wrap">
                {selectedDepartments.length !== 0 &&
                  selectedDepartments.map((items, index) => {
                    const departmentObj = department.find(
                      (dept) => dept.id === items
                    );

                    return (
                      <div
                        key={index}
                        className="border border-[#324D72] px-1 py-0.5 flex items-center rounded-lg cursor-pointer"
                        onClick={() => handleDepartmentChange(departmentObj.id)}
                      >
                        <p className="text-[#324d72] text-[10px] cursor-pointer">
                          {departmentObj.name}
                        </p>
                        <button className="text-base text-[#324D72]">
                          <IoIosClose />
                        </button>
                      </div>
                    );
                  })}
              </div>
            </div>
            <div className="flex flex-wrap items-center justify-between w-full flex-row max-h-[400px] overflow-y-auto mb-6">
              {filteredDepartments.map((items) => {
                return (
                  <div
                    key={items.id}
                    className="flex items-center gap-2 mb-4 text-xs w-full"
                  >
                    <input
                      className="w-[14px] h-[14px] rounded-sm border-[#efefef] bg-[#ececec]"
                      type="checkbox"
                      checked={selectedDepartments.includes(items.id)}
                      onChange={() => handleDepartmentChange(items.id)}
                    />
                    <p className="text-[#1E293B]">{items.name}</p>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default DesktopFeedbackSearch;
