import React, { useContext, useEffect, useRef, useState } from "react";
import { MdArrowBackIos, MdOutlineMenu, MdClose } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import menu from "../json/menu.json";
import Cookies from "js-cookie";
import { NightModeContext } from "../context/NightModeContext";
import { getUserData } from "../service/user.service";
import { UserContext } from "../context/userContext";
import { IoIosArrowDown } from "react-icons/io";
import { CiSettings } from "react-icons/ci";
import "react-loading-skeleton/dist/skeleton.css";
import { useLocation } from "react-router-dom";
import Avatar from "../components/Avatar";
import Notf from "../components/Notf";

const Header = () => {
  const [popUp, setPopUp] = useState(false);
  const [menus, setMenus] = useState(null);
  const [selectedMenu, setSelectedMenu] = useState(0);
  const [showDropdown, setShowDropdown] = useState(false);
  const { setUser, refresh, setRefresh } = useContext(UserContext);
  const [userData, setUserData] = useState(null);
  const { nightMode } = useContext(NightModeContext);

  const dropDownRef = useRef(null);
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await getUserData();
        setUser(data.data);
        setUserData(data.data);
      } catch (err) {
        console.error("Error fetching user data:", err);
      } finally {
        if (refresh) {
          setRefresh(false);
        }
      }
    };

    if (refresh || userData === null) {
      fetchData();
    }
  }, [refresh, setUser, setRefresh, userData]);

  useEffect(() => {
    setMenus(menu);
  }, []);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropDownRef.current && !dropDownRef.current.contains(event.target)) {
        setShowDropdown(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <>
      <header
        className={`sticky top-[12px] rounded-[18px] border-b z-30 mx-6 ${
          nightMode ? "bg-[#372763] border-none" : "bg-white  border-slate-200"
        }`}
      >
        <div className="px-4 py-2 md:py-0 sm:px-6 lg:px-8">
          <div className="flex items-center justify-between md:h-16 sm:h-12 h-12 -mb-px">
            <div className="flex items-center gap-6">
              {location.pathname === "/profile" ||
              location.pathname === "/feed-forward/search" ? (
                <button
                  onClick={() => {
                    if (location.pathname === "/feed-forward") {
                      navigate("/feed-forward");
                    } else {
                      navigate("/");
                    }
                  }}
                  className={`flex  md:me-10 items-center gap-1 text-sm ${
                    nightMode ? "text-white" : "text-[#1E293B]"
                  }`}
                >
                  <MdArrowBackIos />
                  Буцах
                </button>
              ) : (
                <div
                  onClick={() => {
                    navigate("/");
                  }}
                  className="flex items-center gap-2  "
                >
                  <div className="w-[40px] h-[40px]  items-center justify-center rounded-full overflow-hidden border border-[#cfcfcf] cursor-pointer md:hidden flex">
                    {userData?.profileUrl ? (
                      <img
                        src={userData?.profileUrl}
                        className="object-cover w-full h-full"
                        alt="profile"
                      />
                    ) : (
                      <Avatar
                        firstName={userData?.firstName}
                        lastName={userData?.lastName}
                      />
                    )}
                  </div>
                  <p
                    className={`font-semibold ${
                      nightMode ? "text-[#fff]" : "text-[#000]"
                    }`}
                  >
                    Сайн уу, {userData?.firstName}{" "}
                    <span className="text-xl">👋</span>
                  </p>
                </div>
              )}
            </div>
            <div>
              <div className="items-center gap-4 md:flex sm:hidden hidden">
                <Notf />

                {/* <select
                  className={`px-2 py-2 border-none rounded-[10px] ${
                    nightMode
                      ? "text-white bg-[#372763]"
                      : "text-[#202224] bg-gray-100"
                  }`}
                >
                  <option value="en">Eng (US)</option>
                  <option value="mn">MGL</option>
                </select> */}
                {console.log(showDropdown)}
                <div
                  onClick={() => {
                    console.log("clicked");
                    setShowDropdown(!showDropdown);
                  }}
                  className="flex items-center gap-2 cursor-pointer"
                >
                  <div className="w-[40px] h-[40px] flex items-center justify-center rounded-full overflow-hidden border border-[#cfcfcf] cursor-pointer">
                    {userData?.profileUrl ? (
                      <img
                        src={userData?.profileUrl}
                        className="object-cover w-full h-full"
                        alt="profile"
                      />
                    ) : (
                      <Avatar
                        firstName={userData?.firstName}
                        lastName={userData?.lastName}
                      />
                    )}
                  </div>

                  <div>
                    <p
                      className={` ${
                        nightMode ? "text-white" : "text-[#081021]"
                      }`}
                    >
                      {userData?.firstName} {userData?.lastName}
                    </p>
                    <p
                      className={`text-sm text-center ${
                        nightMode ? "text-[#fff]" : "text-[#6c6c72]"
                      }`}
                    >
                      {userData?.position}
                    </p>
                  </div>
                  <IoIosArrowDown
                    className={`transform transition-transform duration-400 ${
                      showDropdown ? "rotate-180" : ""
                    }`}
                  />
                </div>
                {showDropdown && (
                  <div
                    ref={dropDownRef}
                    className={`absolute right-4 top-12 mt-2 w-48 bg-white border border-gray-200 rounded-md z-30 shadow-lg ${
                      nightMode ? "bg-[#372763]" : "bg-white"
                    }`}
                  >
                    <ul className="py-1">
                      <li
                        onClick={() => {
                          navigate("/profile");
                          setShowDropdown(false);
                        }}
                        className="text-[12px] text-sm text-main-blue hover:text-indigo-600 py-1 px-3 cursor-pointer"
                      >
                        Миний мэдээлэл
                      </li>
                    </ul>
                    <ul className="py-1">
                      <li
                        onClick={() => {
                          setShowDropdown(false);
                          Cookies.remove("metacog_token");
                          window.location.reload();
                        }}
                        className="text-[12px] text-sm text-main-blue hover:text-indigo-600 py-1 px-3 cursor-pointer"
                      >
                        Гарах
                      </li>
                    </ul>
                  </div>
                )}
              </div>
              <div className=" items-center gap-4 md:hidden sm:flex flex">
                <Notf />
                <button
                  onClick={() => {
                    setPopUp(true);
                  }}
                >
                  <MdOutlineMenu
                    className={`font-bold text-2xl ${
                      nightMode ? "text-white" : "text-[#2C4360]"
                    }`}
                  />
                </button>
              </div>
            </div>
          </div>
        </div>
      </header>

      {popUp && (
        <div
          onClick={() => {
            setPopUp(false);
          }}
          className={`fixed top-0 left-0 w-full h-screen bg-[#000] bg-opacity-30 z-40 transform transition-all duration-300 ease-in-out ${
            popUp ? "opacity-100" : "opacity-0"
          }`}
          style={{ transitionProperty: "opacity, transform" }}
        >
          <div className="p-4 h-auto bg-white shadow-lg ">
            <div className="flex items-center justify-end">
              <button onClick={() => setPopUp(false)}>
                <MdClose className="text-[#2C4360] text-2xl" />
              </button>
            </div>

            <div className="flex justify-between items-center gap-2 mt-4">
              <div className="flex items-center gap-2">
                <div className="w-[40px] h-[40px] flex items-center justify-center rounded-full overflow-hidden border border-[#cfcfcf] cursor-pointer">
                  {userData?.profileUrl ? (
                    <img
                      src={userData?.profileUrl}
                      className="object-cover w-full h-full"
                      alt="profile"
                    />
                  ) : (
                    <Avatar
                      firstName={userData?.firstName}
                      lastName={userData?.lastName}
                    />
                  )}
                </div>
                <div>
                  <p className="font-semibold text-[#081021]">
                    {userData.firstName}, {userData.lastName}
                  </p>
                  <p className="text-[#6B7280] text-sm">{userData.position}</p>
                </div>
              </div>
              <CiSettings
                onClick={() => {
                  navigate("/profile");
                }}
                className="text-[#2C4360] text-2xl"
              />
            </div>

            <div className="mt-2">
              <button
                className={`w-full text-start mb-2 px-2 py-2 rounded-lg hover:bg-[#f6f6f6] ${
                  selectedMenu === 20
                    ? "bg-[#F4F6FB] text-[#324D72]"
                    : "bg-none text-[#666874]"
                }`}
                onClick={() => {
                  setSelectedMenu(20);
                  navigate(`/profile`);
                }}
              >
                Миний мэдээлэл
              </button>
              {menus?.map((items, l) => {
                if (
                  userData.companyId === 31
                    ? menu.id === 1 || menu.id === 2 || menu.id === 0
                    : items.id === 4 || items.id === 2 || items.id === 0
                ) {
                  return (
                    <button
                      key={l}
                      className={`w-full text-start mb-2 px-2 py-2 rounded-lg hover:bg-[#f6f6f6] ${
                        selectedMenu === l
                          ? "bg-[#F4F6FB] text-[#324D72]"
                          : "bg-none text-[#666874]"
                      }`}
                      onClick={() => {
                        setSelectedMenu(l);
                        navigate(`${items.link}`);
                      }}
                    >
                      {items.name}
                    </button>
                  );
                } else {
                  return <></>;
                }
              })}
              <button
                onClick={() => {
                  setShowDropdown(!showDropdown);
                  Cookies.remove("metacog_token");
                  window.location.reload();
                }}
                className="ps-2 py-2 w-full rounded-lg text-start hover:bg-gray-100 cursor-pointer text-[#666874]"
              >
                Гарах
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Header;
