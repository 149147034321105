import React, { useContext, useState } from "react";
import OnBoarding from "./onboard/OnBoarding";
import { useNavigate } from "react-router-dom";
import ControlOnboard from "./onboard/ControlOnboard";
import { NightModeContext } from "../context/NightModeContext";

const Onboard = () => {
  const [page, setPage] = useState("my");
  const { nightMode, setNightMode } = useContext(NightModeContext);
  const navigate = useNavigate();

  const renderActiveTable = () => {
    switch (page) {
      case "my":
        return <OnBoarding nightMode={nightMode} setNightMode={setNightMode} />;
      case "control":
        return (
          <ControlOnboard nightMode={nightMode} setNightMode={setNightMode} />
        );
      default:
        return null;
    }
  };

  const handlePageChange = (table) => {
    setPage(table);
    navigate(`/onboard/${table}`);
  };
  return (
    <div className="pt-11 w-10/12 m-auto">
      <div className="flex items-center gap-6">
        <button
          onClick={() => {
            handlePageChange("my");
          }}
          className={`text-sm pb-2 ${
            page === "my"
              ? "border-b-2 border-[#2C4360] text-[#2C4360]"
              : "text-[#666874]"
          }`}
        >
          Миний Onboard
        </button>
        <button
          onClick={() => {
            handlePageChange("control");
          }}
          className={`text-sm pb-2 ${
            page === "control"
              ? "border-b-2 border-[#2C4360] text-[#2C4360]"
              : "text-[#666874]"
          }`}
        >
          Хянах Onboard
        </button>
      </div>
      <div>{renderActiveTable()}</div>
    </div>
  );
};

export default Onboard;
