import React, { useEffect } from "react";
import Cookies from "js-cookie";
import { useNavigate, useLocation } from "react-router-dom";

const LoginByMetacog = () => {
  const navigate = useNavigate();
  const { search } = useLocation();
  const params = new URLSearchParams(search);
  const token = params.get("token");

  useEffect(() => {
    if (token) {
      Cookies.set("metacog_token", token, { expires: 7 });
      navigate("/");
    }
  }, [token, navigate]);

  return (
    <div className="h-screen w-full m-auto flex items-center justify-center">
      <p>Redirecting...</p>
    </div>
  );
};

export default LoginByMetacog;
